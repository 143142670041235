import React from 'react'
import AppleLogo from '../assets/brands/AppleLogo'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles';


export default function AppleButton(props) {

  const AppleButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary,
    backgroundColor: 'black',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.5)',
    },
  }));
  
  return (
    <AppleButton startIcon={<AppleLogo logoColor="palette.appleLogo"/>} onClick={props.onClick} variant="contained">{props.message}</AppleButton>
    

  )
}
