import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Divider, Grid
} from "@mui/material";
import moment from 'moment-timezone';
import MaterialUITable from "../components/MaterialUITable";
import { useSelector, useDispatch } from "react-redux";

export default function Others(props) {
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [policyAgent, setPolicyAgent] = useState({});

  useEffect(() => {
    async function init() {

    }
    init()
  }, [])

  return (
    <Box>
      <Typography variant="h4" color="text.primary" sx={{ mb: 5 }}>
        Downloadables{" "}
      </Typography>
      {/* <Typography variant="h6">Policy Agents</Typography>
      <Typography variant="body1" color="text.secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </Typography> */}

      <Box sx={{ mt: 5 }}>
        <Typography variant="h5" sx={{ mb: 1 }} >Forms</Typography>

        <Grid container spacing={2} sx={{}}>

          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Amendment Form.pdf'}
                download>
                Amendment Form
              </a>
            </Typography>
          </Grid>
          {
            IS_VL &&
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6" color="text.primary">
                • {'  '}
                <a href={window.location.origin +
                  '/downloadables/Application For Topup For Variable Insurance Plan.pdf'}
                  download>
                  Application For Topup For Variable Insurance Plan
                </a>
              </Typography>
            </Grid>
          }
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Auto-Debit Arrangement and Credit Card Enrollment Form.pdf'}
                download>
                Auto-Debit Arrangement and Credit Card Enrollment Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Certificate of Release Form.pdf'}
                download>
                Certificate of Release Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Claims-Attending Physicians Statement of Disability.pdf'}
                download>
                Claims-Attending Physicians Statement of Disability
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Claims-Certificate of Claimant.pdf'}
                download>
                Claims-Certificate of Claimant
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Health Statement.pdf'}
                download>
                Health Statement
              </a>
            </Typography>
          </Grid>
          {
            !IS_VL &&
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" color="text.primary">
                  • {'  '}
                  <a href={window.location.origin +
                    '/downloadables/Metrobank Auto Debit Arrangement Form.pdf'}
                    download>
                    Metrobank Auto Debit Arrangement Form
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant="h6" color="text.primary">
                  • {'  '}
                  <a href={window.location.origin +
                    '/downloadables/PNB ADA.pdf'}
                    download>
                    PNB Auto Debit Arrangement Form
                  </a>
                </Typography>
              </Grid>
            </>
          }
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/BDO ADA.pdf'}
                download>
                BDO Auto Debit Arrangement Form
              </a>
            </Typography>
          </Grid>
          {
            !IS_VL &&
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6" color="text.primary">
                • {'  '}
                <a href={window.location.origin +
                  '/downloadables/Policy Administration Request Form.pdf'}
                  download>
                  Policy Administration Request Form
                </a>
              </Typography>
            </Grid>
          }
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Policy Surrender and Variable Life Transaction Form.pdf'}
                download>
                Policy Surrender and Variable Life Transaction Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Privacy Policy and Consent Form.pdf'}
                download>
                Privacy Policy and Consent Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Specimen Signature Form.pdf'}
                download>
                Specimen Signature Form
              </a>
            </Typography>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/COCOLIFE PRIVACY POLICY AND CONSENT FORM ALL.pdf'}
                download>
                Privacy Policy and Consent Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-045-0313-2 (Auto-Debit Arrangement Enrollment Form).pdf'}
                download>
                Auto Debit Arrangement Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-028-0314-6 (Credit Card_ Recurring Payment Enrollment Form) (1).pdf'}
                download>
                Credit Card Recurring Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>            
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-001-0917-4 (Policy Loan Agreement).pdf'}
                download>
                Policy Loan Agreement for Traditional Plan (PLA)
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>            
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/Wire Transfer Form.pdf'}
                download>
                Wire Transfer
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>            
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-008-0801-3 (Amendment Form).pdf'}
                download>
                Amendment Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>            
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-047-0720-3 (Certifcate  of Release).pdf'}
                download>
                Certificate of Release Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>            
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-024-0908-4 (Specimen Signature).pdf'}
                download>
                Specimen Signature Form
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>            
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-009-1118-5 (Health Statement).pdf'}
                download>
                Health Statement
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>            
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-033-1116-5 (Application Form for Variable Life Insurance Transactions (Top-up)) FRONT.pdf'}
                download>
                Application for VL Insurance Transaction (Top Up)
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>            
            <Typography variant="h6" color="text.primary">
              • {'  '}
              <a href={window.location.origin +
                '/downloadables/POL_ADMIN-033-1116-5 (Application Form for Variable Life Insurance Transactions (Withdrawal, Change Allocation and Switching)) BACK.pdf'}
                download>
                Application for VL Insurance Transaction (Withdrawal, Change Allocation and Switching)
              </a>
            </Typography>
          </Grid> */}

        </Grid>
      </Box>

    </Box>
  );
}
