import { SvgIcon } from "@mui/material";
import React from "react";
import { ReactComponent as Logo } from "./apple-svg.svg";
export default function AppleLogo(props) {
  return (
    <SvgIcon>
      <Logo fill={props.logoColor}/>
    </SvgIcon>
  );
}
