import { createSlice } from "@reduxjs/toolkit";
export const registrationSlice = createSlice({
  name: "registration",
  //this is the initial state
  initialState: {
    policyNumber: "",
    firstName: "",
    middleName: "",
    lastName: "",
    suffix: "",
    birthday: "",
    oldUserID: "",
    oldUsername: "",
    oldPassword: "",
    oldEmail: "",
    provider: "",
    providerEmail: "",
    firebaseUID: ""
  },
  //reducers: these are the functions for updating the state of dark mode / light mode
  reducers: {
    trackOldUserID: (state, action) => {
      console.log("registrationSlice.payload?.oldUserID", action.payload?.oldUserID)
      console.log("registrationSlice.payload", action.payload)

      state.oldUserID = action.payload
    },
    trackOldUserData: (state, action) => {
      console.log("registrationSlice.payload", action.payload)

      state.oldUsername = action.payload.username
      state.oldPassword = action.payload.password
      state.oldEmail = action.payload.email
    },
    setProvider: (state, action) => {
      console.log("registrationSlice setProvider", action.payload)
      state.provider = action.payload
      console.log("registrationSlice.provider", state.provider)
    },
    setProviderEmail: (state, action) => {
      console.log("registrationSlice setProviderEmail", action.payload)
      state.providerEmail = action.payload
      console.log("registrationSlice.providerEmail", state.providerEmail)
    },
    setFirebaseUID: (state, action) => {
      console.log("registrationSlice setFirebaseUID", action.payload)
      state.firebaseUID = action.payload
      console.log("registrationSlice.firebaseUID", state.firebaseUID)
    },
    updatePage1: (state, action) => {
      console.log("updatePage1", action.payload)

      state.policyNumber = action.payload?.policyNumber
      state.firstName = action.payload?.firstName
      state.middleName = action.payload?.middleName
      state.lastName = action.payload?.lastName
      state.suffix = action.payload?.suffix
      state.birthday = action.payload?.birthday
      state.mobileNumber = action.payload?.mobileNumber
      state.emailAddress = action.payload?.emailAddress
      state.policyAgreementDate = action.payload?.policyAgreementDate
      state.username = action.payload?.emailAddress
    },
    updatePage2: (state, action) => {
      console.log("updatePage2", action.payload)

      // state.emailAddress = action.payload?.emailAddress
      // state.username = action.payload?.username
      state.password = action.payload?.password
      state.policyAgreementDate = action.payload?.policyAgreementDate
    },
  },
});

export const { setFirebaseUID, setProvider, setProviderEmail,
  trackOldUserID, updatePage1, updatePage2, trackOldUserData } = registrationSlice.actions;
export default registrationSlice.reducer;
