import { configureStore } from '@reduxjs/toolkit'
import { loadState } from './localStorage'

import registrationReducer from './features/Registration/RegistrationSlice'
import themeReducer from './features/Theming/ThemeModeSlice'
import policyReducer from './features/Theming/PolicyNumberSlice'
import loadingReducer from './features/LoadingControl/LoadingControlSlice'
import idleReducer from './features/IdleControl/IdleControlSlice'
import logoutReducer from './features/LogoutControl/LogoutControlSlice'
//loads the value of state that is stored in local storage
//fetches using loadState()
const initialState = loadState()
export default configureStore({
    reducer: {
        theme: themeReducer,
        registration: registrationReducer,
        policyNumber: policyReducer,
        loadingState: loadingReducer,
        idleState: idleReducer,
        logoutState: logoutReducer,
    },

    //preloadedState: passes the value of initialState as the value of the theme state in store
    preloadedState: initialState,
})