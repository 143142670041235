import React, {
  useState,
  useEffect,
} from 'react';
import { Button, Divider, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from 'moment-timezone';
import axios from 'axios';
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APILoadingOverlay from "../components/APILoadingOverlay";
import APIErrorModal from "../components/APIErrorModal";

//for billing form printing
import coco_logo from "../assets/cocolife-horizontal.png";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { renderToString } from "react-dom/server";

export default function ViewPolicy({ getPolicyNumber }) {
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [policyNumber, setPolicyNumber] = useState("");
  const [policyProfile, setPolicyProfile] = useState("");
  const [email, setEmail] = useState("");
  const [transactionList, setTransactionList] = useState([]);
  const [transactionList2, setTransactionList2] = useState([]);
  const [insuredProfileData, setInsuredProfileData] = useState([]);
  const [policyOwnerProfileData, setPolicyOwnerProfileData] = useState([]);

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [modalBasicInfoEmailSent, setModalBasicInfoEmailSent] = useState(false);
  const [modalServerError, setModalServerError] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    maxWidth: 600,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const [sendButtonLabel, setSendButtonLabel] = useState('Send Basic Info Email');
  const [sendButtonColor, setSendButtonColor] = useState('primary')

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    async function init() {
      console.log("getPolicyNumber handleViewPolicy", getPolicyNumber())

      let policyProfileReqData = {
        // policyNumber: 'T0095469',
        policyNumber: POLICY_DATA,
      }
      if (getPolicyNumber() != null) {
        policyProfileReqData = {
          // policyNumber: 'T0095469',
          policyNumber: getPolicyNumber(),
        }
      }

      setPolicyNumber(policyProfileReqData.policyNumber)
      console.log("policyProfileReqData", policyProfileReqData)

      await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', policyProfileReqData).then(
        async (res) => {
          // console.log("getPolicyProfile res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('getPolicyProfile')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPolicyProfile res is null')
            return
          }

          if (res.data.Success) {
            console.log('policy profile updated')
            setPolicyProfile(res.data.Data);

            let transactionList = [
              {
                data: 'Policy Currency',
                value: res.data.Data.PolicyCurrency
              },
              {
                data: 'Current Face Amount',
                value: res.data.Data.CurrentFaceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              },
              {
                data: 'Face Amount',
                value: res.data.Data.FaceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              },
              {
                data: 'Total Premium',
                value: res.data.Data.TotalPremium.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              },
              {
                data: 'Billing Premium',
                value: res.data.Data.BillingPremium.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              },
            ]

            // if (IS_VL) {
            //   transactionList = [
            //     {
            //       data: 'Policy Currency',
            //       value: res.data.Data.PolicyCurrency
            //     },
            //     {
            //       data: 'Current Face Amount',
            //       value: res.data.Data.CurrentFaceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            //     },
            //     {
            //       data: 'Face Amount',
            //       value: res.data.Data.FaceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            //     },
            //   ]
            // }

            setTransactionList(transactionList);

            let issueDate = res.data.Data.IssueDate;
            if (moment(res.data.Data.IssueDate).isValid()) {
              issueDate = moment(res.data.Data.IssueDate, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')
            }

            let transactionList2 = [
              {
                data: 'Non-Forfeiture Option',
                value: res.data.Data.NonforfeitureOption
              },
              {
                data: 'Dividend Option',
                value: res.data.Data.DividendOption
              },
              {
                data: 'Effective Date',
                value: issueDate
              },
            ]

            // if (IS_VL) {
            //   transactionList2 = [
            //     {
            //       data: 'Total Premium',
            //       value: res.data.Data.TotalPremium.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            //     },
            //     {
            //       data: 'Billing Premium',
            //       value: res.data.Data.BillingPremium.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            //     },
            //     {
            //       data: 'Effective Date',
            //       value: issueDate
            //     },
            //   ]
            // }

            setTransactionList2(transactionList2)

          } else {
            setapiName('getPolicyProfile')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPolicyProfile res', res.data.Response)
            return
          }
        })


      let insuredProfileReqData = {
        // policyNumber: 'T0095469',
        policyNumber: POLICY_DATA,
      }

      await axios.post(process.env.REACT_APP_API_URL + '/api/getInsuredProfile', insuredProfileReqData).then(
        async (res) => {
          // console.log("getInsuredProfile res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('getInsuredProfile')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'getInsuredProfile res is null')
            return
          }

          if (res.data.Success) {
            console.log('insured profile updated')

            let insuredBirthdate = res.data.Data.Birthday;
            if (moment(res.data.Data.Birthday).isValid()) {
              insuredBirthdate = moment(res.data.Data.Birthday, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')
            }

            let insuredProfileData = [
              {
                data: 'Name',
                value: res.data.Data.Name
              },
              {
                data: 'Birth Date',
                value: insuredBirthdate
              },
            ]

            setInsuredProfileData(insuredProfileData);

          } else {
            setapiName('getInsuredProfile')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'getInsuredProfile res', res.data.Response)
            return
          }
        })





      let policyOwnerProfileReqData = {
        // policyNumber: 'T0095469',
        policyNumber: POLICY_DATA,
      }

      await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyOwnerProfile', policyOwnerProfileReqData).then(
        async (res) => {
          // console.log("getPolicyOwnerProfile res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('getPolicyOwnerProfile')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPolicyOwnerProfile res is null')
            return
          }

          if (res.data.Success) {
            console.log('policy owner profile updated')

            let policyOwnerBirthdate = res.data.Data.Birthday;
            if (moment(res.data.Data.Birthday).isValid()) {
              policyOwnerBirthdate = moment(res.data.Data.Birthday, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')
            }
            let policyOwnerProfileData = [
              {
                data: 'Name',
                value: res.data.Data.Name
              },
              {
                data: 'Birth Date',
                value: policyOwnerBirthdate
              },
              {
                data: 'Company',
                value: res.data.Data.Company
              },
              {
                data: 'Mailing Address',
                value: res.data.Data.MailingAddress
              },
              {
                data: 'Permanent Address',
                value: res.data.Data.PermanentAddress
              },
              {
                data: 'Email Address',
                value: res.data.Data.EmailAddress
              },
              {
                data: 'Viber',
                value: res.data.Data.Viber
              },
              {
                data: 'Mobile Number',
                value: res.data.Data.MobileNumber
              },
              {
                data: 'Phone',
                value: res.data.Data.Phone
              },
            ]

            setPolicyOwnerProfileData(policyOwnerProfileData);

          } else {
            setapiName('getPolicyOwnerProfile')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPolicyOwnerProfile res', res.data.Response)
            return
          }
        })

      await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByPolicyNumber', {
        policyNumber: POLICY_DATA
      }).then(
        async (getUserByPolicyNumberRes) => {
          console.log("getUserByPolicyNumberRes getUserByPolicyNumberRes.data.user", getUserByPolicyNumberRes.data.user);

          if (getUserByPolicyNumberRes.data.user != null) {
            setEmail(getUserByPolicyNumberRes.data.user.recordset[0].email)
          } else {
            await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByPolicyNumber', {
              policyNumber: POLICY_DATA
            }).then(
              async (getUserByPolicyNumberRes) => {
                console.log("getUserByPolicyNumberRes getUserByPolicyNumberRes.data.user", getUserByPolicyNumberRes.data.user);

                if (getUserByPolicyNumberRes.data.user != null) {
                  setEmail(getUserByPolicyNumberRes.data.user.recordset[0].email)
                }
              })
          }
        })

      setIsLoading(false)
    }

    init()
  }, [])


  //printing
  const HEIGHT = 842.4 * 0.749
  const WIDTH = 597.6 * 0.749
  const pdf = new jsPDF('p', 'pt', [597.6, 842.4]);


  const COCO_HEADER = () => (
    <div style={{ color: "black" }}>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", maxHeight: "10vh" }}>
        <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
          <img alt={"coco_logo"} src={coco_logo} style={{ width: "100%", alignSelf: "center" }} />
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-end", paddingLeft: "25%" }}>
          <div style={{ display: "flex", flex: 1, maxHeight: "5vh", backgroundColor: "rgba(24,99,168,1)", alignItems: "center" }}>
            <div style={{ fontSize: "1.1em", fontWeight: "bold", color: "white", marignleft: '2' }}>
              {` BASIC INFORMATION`}
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, maxHeight: "2vh", backgroundColor: "rgba(32,176,75,1)", alignItems: "center" }}>
            <div style={{ fontSize: "0.8em", fontWeight: "bold", marginLeft: "2", color: "white" }}>
              {` Policyholder`}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  const POLICY_STYLE = { fontSize: "0.6em", letterSpacing: "0.1em", textAlign: "left", marginTop: "1vh" }
  const HEAD_BIG_TEXT_CONTAINER = { display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(24,99,168,1)" }
  const HEADER_BIG_TEXT = { fontSize: "0.9em", letterSpacing: "0.1em", color: "white", fontWeight: "bold" }


  const GET_VL_OR_TRAD_POLICY_PROFILE = () => {
    if (IS_VL) {
      return (
        <>
          <div style={{ ...HEAD_BIG_TEXT_CONTAINER, marginTop: "2vh" }}>
            <div style={HEADER_BIG_TEXT}>
              POLICY PROFILE
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "row", paddingLeft: `${WIDTH * 0.025}pt` }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={POLICY_STYLE}>
                {
                  `Policy Number: ${policyNumber}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  `Plan Name: ${policyProfile.PlanName}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[0].value == null || transactionList[0].value == '' || transactionList[0].value == '-' ?
                    `${transactionList[0].data}: --`
                    :
                    `${transactionList[0].data}: ${transactionList[0].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[1].value == null || transactionList[1].value == '' || transactionList[1].value == '-' ?
                    `${transactionList[1].data}: --`
                    :
                    `${transactionList[1].data}: ${transactionList[1].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[2].value == null || transactionList[2].value == '' || transactionList[2].value == '-' ?
                    `${transactionList[2].data}: --`
                    :
                    `${transactionList[2].data}: ${transactionList[2].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[3].value == null || transactionList[3].value == '' || transactionList[3].value == '-' ?
                    `${transactionList[3].data}: --`
                    :
                    `${transactionList[3].data}: ${transactionList[3].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[4].value == null || transactionList[4].value == '' || transactionList[4].value == '-' ?
                    `${transactionList[4].data}: --`
                    :
                    `${transactionList[4].data}: ${transactionList[4].value}`
                }
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", paddingLeft: "10%" }}>
              <div style={POLICY_STYLE}>
                {
                  policyProfile.PayMode == null || policyProfile.PayMode == '' || policyProfile.PayMode == '-' ?
                    `Payment Method: --`
                    :
                    `Payment Method: ${policyProfile.PayMode}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList2[2].value == null || transactionList2[2].value == '' || transactionList2[2].value == '-' ?
                    `${transactionList2[2].data}: --`
                    :
                    <>
                      {moment(transactionList2[2].data).isValid() ?
                        `${transactionList2[2].data}: ${moment(transactionList2[2].value, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')}`
                        :
                        `${transactionList2[2].data}: ${transactionList2[2].value}`
                      }
                    </>
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  policyProfile.NextDueDate == null || policyProfile.NextDueDate == '' || policyProfile.NextDueDate == '-' ?
                    `Next Due Date: --`
                    :
                    <>
                      {moment(policyProfile.NextDueDate).isValid() ?
                        `Next Due Date: ${moment(policyProfile.NextDueDate, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')}`
                        :
                        `Next Due Date: ${policyProfile.NextDueDate}`
                      }
                    </>
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  `Status: ${policyProfile.Status}`
                }
              </div>
            </div>
          </div>
        </>
      )
    } else {
      return (
        <>
          <div style={{ ...HEAD_BIG_TEXT_CONTAINER, marginTop: "2vh" }}>
            <div style={HEADER_BIG_TEXT}>
              POLICY PROFILE
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, flexDirection: "row", paddingLeft: `${WIDTH * 0.025}pt` }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={POLICY_STYLE}>
                {
                  `Policy Number: ${policyNumber}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  `Plan Name: ${policyProfile.PlanName}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[0].value == null || transactionList[0].value == '' || transactionList[0].value == '-' ?
                    `${transactionList[0].data}: --`
                    :
                    `${transactionList[0].data}: ${transactionList[0].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[2].value == null || transactionList[2].value == '' || transactionList[2].value == '-' ?
                    `${transactionList[2].data}: --`
                    :
                    `${transactionList[2].data}: ${transactionList[2].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[3].value == null || transactionList[3].value == '' || transactionList[3].value == '-' ?
                    `${transactionList[3].data}: --`
                    :
                    `${transactionList[3].data}: ${transactionList[3].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList[4].value == null || transactionList[4].value == '' || transactionList[4].value == '-' ?
                    `${transactionList[4].data}: --`
                    :
                    `${transactionList[4].data}: ${transactionList[4].value}`
                }
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", paddingLeft: "10%" }}>
              <div style={POLICY_STYLE}>
                {
                  policyProfile.PayMode == null || policyProfile.PayMode == '' || policyProfile.PayMode == '-' ?
                    `Payment Method: --`
                    :
                    `Payment Method: ${policyProfile.PayMode}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList2[0].value == null || transactionList2[0].value == '' || transactionList2[0].value == '-' ?
                    `${transactionList2[0].data}: --`
                    :
                    `${transactionList2[0].data}: ${transactionList2[0].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList2[1].value == null || transactionList2[1].value == '' || transactionList2[1].value == '-' ?
                    `${transactionList2[1].data}: --`
                    :
                    `${transactionList2[1].data}: ${transactionList2[1].value}`
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  transactionList2[2].value == null || transactionList2[2].value == '' || transactionList2[2].value == '-' ?
                    `${transactionList2[2].data}: --`
                    :
                    <>
                      {moment(transactionList2[2].data).isValid() ?
                        `${transactionList2[2].data}: ${moment(transactionList2[2].value, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')}`
                        :
                        `${transactionList2[2].data}: ${transactionList2[2].value}`
                      }
                    </>
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  policyProfile.NextDueDate == null || policyProfile.NextDueDate == '' || policyProfile.NextDueDate == '-' ?
                    `Next Due Date: --`
                    :
                    <>
                      {moment(policyProfile.NextDueDate).isValid() ?
                        `Next Due Date: ${moment(policyProfile.NextDueDate, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')}`
                        :
                        `Next Due Date: ${policyProfile.NextDueDate}`
                      }
                    </>
                }
              </div>
              <div style={POLICY_STYLE}>
                {
                  policyProfile.Status == null || policyProfile.Status == '' || policyProfile.Status == '-' ?
                    `Status: --`
                    :
                    `Status: ${policyProfile.Status}`
                }
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  const COCO_POLICY_INFO = () => (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", marginTop: "3vh", color: "black" }}>
      <div style={{ display: "flex", flex: 7, flexDirection: "column" }}>
        {GET_VL_OR_TRAD_POLICY_PROFILE()}
        <div style={{ ...HEAD_BIG_TEXT_CONTAINER, marginTop: "2vh" }}>
          <div style={HEADER_BIG_TEXT}>
            POLICYHOLDER PROFILE
          </div>
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", paddingLeft: `${WIDTH * 0.025}pt` }}>
          <div style={{ display: "flex", flex: 6, flexDirection: "column" }}>
            <div style={POLICY_STYLE}>
              {
                insuredProfileData[0].value == null || insuredProfileData[0].value == '' || insuredProfileData[0].value == '-' ?
                  `${insuredProfileData[0].data}: --`
                  :
                  `${insuredProfileData[0].data}: ${insuredProfileData[0].value}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                insuredProfileData[1].value == null || insuredProfileData[1].value == '' || insuredProfileData[1].value == '-' ?
                  `${insuredProfileData[1].data}: --`
                  :
                  `${insuredProfileData[1].data}: ${insuredProfileData[1].value}`
              }
            </div>
          </div>
        </div>

      </div>
    </div>
  )

  const COCO_FOOTER = () => (
    <div div={{ color: "black" }}>
      <div style={{ ...HEAD_BIG_TEXT_CONTAINER, marginTop: "2vh", }}>
        <div style={HEADER_BIG_TEXT}>
          POLICYOWNER PROFILE
        </div>
      </div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", paddingLeft: `${WIDTH * 0.025}pt` }}>
        <div style={{ display: "flex", flex: 6, flexDirection: "column" }}>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[0].value == null || policyOwnerProfileData[0].value == '' || policyOwnerProfileData[0].value == '-' ?
                `${policyOwnerProfileData[0].data}: --`
                :
                `${policyOwnerProfileData[0].data}: ${policyOwnerProfileData[0].value}`
            }
          </div>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[1].value == null || policyOwnerProfileData[1].value == '' || policyOwnerProfileData[1].value == '-' ?
                `${policyOwnerProfileData[1].data}: --`
                :
                `${policyOwnerProfileData[1].data}: ${policyOwnerProfileData[1].value}`
            }
          </div>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[2].value == null || policyOwnerProfileData[2].value == '' || policyOwnerProfileData[2].value == '-' ?
                `${policyOwnerProfileData[2].data}: --`
                :
                `${policyOwnerProfileData[2].data}: ${policyOwnerProfileData[2].value}`
            }
          </div>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[3].value == null || policyOwnerProfileData[3].value == '' || policyOwnerProfileData[3].value == '-' ?
                `${policyOwnerProfileData[3].data}: --`
                :
                `${policyOwnerProfileData[3].data}: ${policyOwnerProfileData[3].value}`
            }
          </div>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[4].value == null || policyOwnerProfileData[4].value == '' || policyOwnerProfileData[4].value == '-' ?
                `${policyOwnerProfileData[4].data}: --`
                :
                `${policyOwnerProfileData[4].data}: ${policyOwnerProfileData[4].value}`
            }
          </div>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[5].value == null || policyOwnerProfileData[5].value == '' || policyOwnerProfileData[5].value == '-' ?
                `${policyOwnerProfileData[5].data}: --`
                :
                `${policyOwnerProfileData[5].data}: ${policyOwnerProfileData[5].value}`
            }
          </div>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[6].value == null || policyOwnerProfileData[6].value == '' || policyOwnerProfileData[6].value == '-' ?
                `${policyOwnerProfileData[6].data}: --`
                :
                `${policyOwnerProfileData[6].data}: ${policyOwnerProfileData[6].value}`
            }
          </div>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[7].value == null || policyOwnerProfileData[7].value == '' || policyOwnerProfileData[7].value == '-' ?
                `${policyOwnerProfileData[7].data}: --`
                :
                `${policyOwnerProfileData[7].data}: ${policyOwnerProfileData[7].value}`
            }
          </div>
          <div style={POLICY_STYLE}>
            {
              policyOwnerProfileData[8].value == null || policyOwnerProfileData[8].value == '' || policyOwnerProfileData[8].value == '-' ?
                `${policyOwnerProfileData[8].data}: --`
                :
                `${policyOwnerProfileData[8].data}: ${policyOwnerProfileData[8].value}`
            }
          </div>
        </div>
      </div>
    </div>
  )

  const COCO_PROFILE_DETAILS = () => (
    <div style={{
      display: "flex", flex: 1, backgroundColor: "rgba(207,236,240,0.9)", color: "black",
      width: `${WIDTH}pt`, height: `${HEIGHT}pt`
    }}>
      <div style={{ paddingTop: `${HEIGHT * 0.0125}pt`, paddingBottom: `${HEIGHT * 0.0125}pt`, paddingRight: `${WIDTH * 0.025}pt`, paddingLeft: `${WIDTH * 0.025}pt` }}>
        {COCO_HEADER()}
        {COCO_POLICY_INFO()}
        {COCO_FOOTER()}
        <div style={{ backgroundColor: "rgba(207,236,240,0.9)", fontSize: "0.5em", letterSpacing: "0.1em", textAlign: "left", marginTop: "7vh" }}>
          Date/Time: {`${moment().format('MMMM DD, YYYY HH:mm:ss Z')}`}
        </div>
      </div>
    </div>
  )

  const PRINT_BASIC_INFO = async () => {
    const string = renderToString(<COCO_PROFILE_DETAILS />);
    pdf.html(string)
      .then(async () => {
        pdf.autoPrint({ variant: 'non-conform' });
        pdf.save(`Basic Information - ${policyProfile.PlanName}`)
        window.open(pdf.output('bloburl'), '_blank')
      })
  };
  //end printing 

  const EMAIL_BASIC_INFO = async () => {

    setSendButtonLabel('Sending...')

    const string = renderToString(<COCO_PROFILE_DETAILS />);
    pdf.html(string)
      .then(async () => {

        let formatedDOB = moment(policyOwnerProfileData[1].value).format('MMMDDYYYY')
        let password = POLICY_DATA + formatedDOB

        console.log("EMAIL_BASIC_INFO password", password)

        let htmlTmp =
          "This is your Policy's Basic Information that you have requested.<br/>" +
          "<br/>" +
          "The Password to your file is your Policy Number plus the Policy Owner's Date of Birth in MMMDDYYY format.<br/>" +
          "For example, with Policy Number V0000001 and Policy Owner's Date of Birth January 28, 1988, the password would be V0000001Jan281988.<br/>" +
          "<br/>";

        const params = {
          email: email,
          subject: "Your Cocolife Policy's Basic Information",
          html: htmlTmp,
          attachment: btoa(pdf.output()),
          attachmentName: `Basic Information - ${policyProfile.PlanName}`,
          attachmentType: `pdf`,
          passwordProtected: true,
          password: password,
        }

        let formData = new FormData()
        formData.append('file', new Blob([pdf.output('blob')]), params.attachmentName + "." + params.attachmentType)

        let config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }

        await axios.post(process.env.REACT_APP_API_URL + `/api/sendMail?email=${params.email}&subject=${params.subject}&html=${params.htmlTmp}&attachmentName=${params.attachmentName}&attachmentType=${params.attachmentType}&passwordProtected=${params.passwordProtected}&password=${params.password}`, {
          ...params
        }).then(
          (res_) => {

            if (res_ === 'null') {
              console.log("sendmail failed:", res_)
              setSendButtonLabel('Sending Failed! Resend Basic Info Email?')
              setSendButtonColor('error')
              return
            }

            if (res_.data.data.Success) {
              console.log("sendmail success:", res_)
              setSendButtonLabel('Email Sent! Resend Basic Info Email?')
              setSendButtonColor('success')
              return
            }
          }
        )

      })
  };


  return (
    <Box>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalBasicInfoEmailSent}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalBasicInfoEmailSent(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalBasicInfoEmailSent}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Basic Info Email Sent!
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              We have sent a password protected Basic Information to your registered Email.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Follow the instructions in the email sent to access your Basic Information.
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setModalBasicInfoEmailSent(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalServerError}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalServerError(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalServerError}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Server Error
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              There was an issue with the server.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Please try again.
            </Typography>

            <Button variant="contained"
              onClick={() => {
                window.location.reload(true);
                setModalServerError(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <APILoadingOverlay customlabel={loadingCustomLabel}
        open={isLoading} setOnParent={(value) => setIsLoading(value)}>

        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h5" sx={{ mt: 3 }} color="text.primary">
                {policyProfile.PlanName + " "}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="h6" sx={{ mt: 1 }} color="text.linkActive">
                Policy Number: {policyNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
            sx={{ display: 'row', }}>
            {IS_VL ?
              <Button
                sx={{ mt: 3 }}
                variant="contained"
                autoFocus
                color={sendButtonColor}
                disabled={sendButtonLabel === 'Sending...'}
                onClick={() => {
                  EMAIL_BASIC_INFO();
                }} >
                {sendButtonLabel}
              </Button>
              :
              <Button
                sx={{ mt: 3 }}
                variant="contained"
                onClick={() => {
                  PRINT_BASIC_INFO();
                }} autoFocus >
                Print Basic Info
              </Button>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper variant="outlined" sx={{ p: 2, mt: 3, userSelect: "none" }} >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Status
                  </Typography>
                  <Typography variant="h6">{policyProfile.Status}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Pay Mode
                  </Typography>
                  <Typography variant="h6">{policyProfile.PayMode}</Typography>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Typography variant="subtitle2" color="text.subtitle2">
              Issue Date:
            </Typography>
            <Typography variant="h6">September 29, 2014</Typography>
          </Grid> */}
                <Grid item xs={12} sm={4} md={4} lg={3} xl={2}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Next Due Date
                  </Typography>
                  <Typography variant="h6">
                    {moment(policyProfile.NextDueDate).isValid() ?
                      moment(policyProfile.NextDueDate, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')
                      :
                      policyProfile.NextDueDate
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {/* End of Summary */}

        <Divider sx={{ mt: 2, mb: 5 }} />
        <Typography variant="h6" sx={{ mt: 2, mb: 2 }} color="text.primary">
          Policy Profile{" "}
        </Typography>
        <Paper variant="outlined" sx={{ p: 2, userSelect: "none" }} >
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {transactionList.map((row, index) => {
                      if (!IS_VL && row.data === "Current Face Amount") {
                        return;
                      }
                      return <TableRow
                        hover
                        key={index}
                        sx={{
                          // "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          size="small"
                          component="th"
                          align="left"
                          scope="row"
                          padding="none"
                          sx={{ width: "200px" }}
                        >
                          <Typography variant="body1">{row.data}</Typography>
                        </TableCell>
                        <TableCell size="small" align="left">
                          <Typography variant="body1">{(row.value === '' || row.value === null || row.value === '-') ? '--' : row.value}</Typography>
                        </TableCell>
                      </TableRow>
                    }
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
              <TableContainer>
                <Table size="medium" aria-label="simple table">
                  <TableBody>
                    {transactionList2.map((row, index) => {
                      if (!IS_VL && row.data === "Current Face Amount") {
                        return;
                      }
                      if (IS_VL && row.data === 'Non-Forfeiture Option' ||
                        IS_VL && row.data === 'Dividend Option'
                      ) {
                        return;
                      }
                      return (
                        <TableRow
                          hover
                          key={index}
                          sx={{
                            // "&:last-child td, &:last-child th": { border: 1 },
                          }}
                        >
                          <TableCell
                            size="small"
                            component="th"
                            align="left"
                            scope="row"
                            padding="none"
                            sx={{ width: "200px" }}
                          >
                            <Typography variant="body1">{row.data}</Typography>
                          </TableCell>
                          <TableCell size="small" align="left">
                            <Typography variant="body1">{(row.value === '' || row.value === null || row.value === '-') ? '--' : row.value}</Typography>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                    <TableRow />
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
        <Divider sx={{ mt: 2, mb: 5 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }} color="text.primary">
              Insured Profile{" "}
            </Typography>
            <Paper variant="outlined" sx={{ p: 2, userSelect: "none" }}>
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {insuredProfileData.map((row, index) => (
                      <TableRow
                        hover
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          size="small"
                          component="th"
                          align="left"
                          scope="row"
                          padding="none"
                          sx={{ width: "200px" }}
                        >
                          <Typography variant="body1">{row.data}</Typography>
                        </TableCell>
                        <TableCell size="small" align="left">
                          <Typography variant="body1">{(row.value === '' || row.value === null || row.value === '-') ? '--' : row.value}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography variant="h6" sx={{ mt: 2, mb: 2 }} color="text.primary">
              Policy Owner Profile
            </Typography>
            <Paper variant="outlined" sx={{ p: 2, userSelect: "none" }} >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableBody>
                    {policyOwnerProfileData.map((row, index) => (
                      <TableRow
                        hover
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          size="small"
                          component="th"
                          align="left"
                          scope="row"
                          padding="none"
                          sx={{ width: "200px" }}
                        >
                          <Typography variant="body1">{row.data}</Typography>
                        </TableCell>
                        <TableCell size="small" align="left">
                          <Typography variant="body1">{(row.value === '' || row.value === null || row.value === '-') ? '--' : row.value}</Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow />
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
        </Grid>
      </APILoadingOverlay>
    </Box >
  );
}
