import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Divider, Grid
} from "@mui/material";
import moment from 'moment-timezone';
import MaterialUITable from "../components/MaterialUITable";
import APIErrorModal from "../components/APIErrorModal";
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import APILoadingOverlay from "../components/APILoadingOverlay";
import axios from 'axios';

export default function Coverages(props) {
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  // const USERNAME = useSelector((state) => state.username.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [coverageDetails, setCoverageDetails] = useState({});
  const [premiumBreakDownDataList, setPremiumBreakDownDataList] = useState({});
  const [premiumBreakDown, setPremiumBreakDown] = useState({});
  const [benefitsSchedule, setBenefitsSchedule] = useState({});
  const [benefitsScheduleTableData, setBenefitsScheduleTableData] = useState({});
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])


  useEffect(() => {
    async function init() {

      return await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByPolicyNumber', {
        policyNumber: POLICY_DATA,
      }).then(async (getUserByPolicyNumberRes) => {
        if (getUserByPolicyNumberRes.data.user === false) {
          //  big error here
          console.log("User not found")
          return
        }
        console.log("getUserByPolicyNumberRes", getUserByPolicyNumberRes)

        let username = getUserByPolicyNumberRes.data.user.recordset[0].username
        console.log("username", username)

        let premiumBreakdownReqData = {
          // policyNumber: 'T0095469',
          policyNumber: POLICY_DATA,
          username: POLICY_DATA,
          // username: username,
        }

        await axios.post(process.env.REACT_APP_API_URL + '/api/getPremiumBreakdown', premiumBreakdownReqData)
          .then(async (res) => {
            // console.log("getPremiumBreakdown res.data.Data", res.data.Data);

            if (res === 'null') {
              setapiName('getPremiumBreakdown')
              setapiResponse({
                Code: '408',
                Message: "API request timeout."
              })
              setAPIErrorModalOpen(true)
              console.log('issue', 'getPremiumBreakdown res is null')
              return
            }

            if (res.data.Success) {
              let newPremiumBreakdownDataList = [];
              let newPremiumBreakdown = {};

              res.data.Data.PremiumBreakdownList.map((item, index) => {
                item.Annual = item.Annual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                item.Monthly = item.Monthly.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                item.Quarterly = item.Quarterly.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                item.SemiAnnual = item.SemiAnnual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

                if (item.Plan != 'SUBTOTAL' && item.Plan != 'TOTAL' && item.Plan != 'CURRENT PREMIUM') {
                  // if (item.Plan != 'SUBTOTAL') {
                  if (newPremiumBreakdownDataList.length > 0) {
                    newPremiumBreakdownDataList = [...newPremiumBreakdownDataList, item]
                  } else {
                    newPremiumBreakdownDataList = [item]
                  }
                } else if (item.Plan === 'SUBTOTAL') {
                  newPremiumBreakdown.subtotal = item;
                } else if (item.Plan === 'TOTAL') {
                  newPremiumBreakdown.total = item;
                } else if (item.Plan === 'CURRENT PREMIUM') {
                  newPremiumBreakdown.currentPremium = item;
                }
              })

              console.log('premium breakdown updated')

              setPremiumBreakDownDataList(newPremiumBreakdownDataList);
              setPremiumBreakDown(newPremiumBreakdown);
              // console.log("coverageDetails", coverageDetails)




              let coverageDetailsReqData = {
                // policyNumber: 'T0095469',
                policyNumber: POLICY_DATA,
              }

              await axios.post(process.env.REACT_APP_API_URL + '/api/getCoverageDetails', coverageDetailsReqData)
                .then(async (res) => {
                  console.log("getCoverageDetails res.data.Data", res.data.Data);

                  if (res === 'null') {
                    setapiName('getCoverageDetails')
                    setapiResponse({
                      Code: '408',
                      Message: "API request timeout."
                    })
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getCoverageDetails res is null')
                    return
                  }

                  if (res.data.Success) {

                    res.data.Data.CoverageDetailsList.map((item, index) => {
                      if (moment(item.Maturity).isValid()) {
                        item.Maturity = moment(item.Maturity, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                      }
                      if (moment(item.PayEnd).isValid()) {
                        item.PayEnd = moment(item.PayEnd, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                      }
                      item.FaceAmount = item.FaceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                      item.Premium = item.Premium.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    })

                    setCoverageDetails(res.data.Data.CoverageDetailsList);
                    console.log("setCoverageDetails", coverageDetails);



                    let benefitScheduleReqData = {
                      // policyNumber: 'T0095469',
                      policyNumber: POLICY_DATA,
                    }

                    if (!IS_VL) {
                      await axios.post(process.env.REACT_APP_API_URL + '/api/getBenefitSchedule', benefitScheduleReqData)
                        .then(async (res) => {
                          console.log("getBenefitSchedule res.data.Data", res.data.Data);

                          if (res === 'null') {
                            setapiName('getBenefitSchedule')
                            setapiResponse({
                              Code: '408',
                              Message: "API request timeout."
                            })
                            setAPIErrorModalOpen(true)
                            console.log('issue', 'getBenefitSchedule res is null')
                            return
                          }

                          if (res.data.Success) {
                            res.data.Data.SOB.map((item, index) => {
                              if (moment(item.DueDates).isValid()) {
                                item.DueDates = moment(item.DueDates, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                              }
                              item.GuaranteedCashBenefits = item.GuaranteedCashBenefits.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            })

                            console.log("setBenefitsSchedule", res.data.Data)
                            setBenefitsSchedule(res.data.Data)
                            setBenefitsScheduleTableData(res.data.Data.SOB);

                          } else {
                            setapiName('getBenefitSchedule')
                            setapiResponse(res.data)
                            setAPIErrorModalOpen(true)
                            console.log('issue', 'getBenefitSchedule res', res.data.Response)
                            return
                          }
                        })
                    } else {
                      setBenefitsScheduleTableData([]);
                    }

                  } else {
                    setapiName('getCoverageDetails')
                    setapiResponse(res.data)
                    setAPIErrorModalOpen(true)
                    console.log('issue', 'getCoverageDetails res', res.data.Response)
                    return
                  }
                })

            } else {
              setapiName('getPremiumBreakdown')
              setapiResponse(res.data)
              setAPIErrorModalOpen(true)
              console.log('issue', 'getPremiumBreakdown res', res.data.Response)
              return
            }
          })

        setIsLoading(false)
      })

      // let premiumBreakDown = {
      //   data: {
      //     "getPremiumBreakdownResult": {
      //       "Data": {
      //         "PremiumBreakdownList": [
      //           {
      //             "Annual": 13936.00,
      //             "Monthly": 1358.76,
      //             "Plan": "FUTURE SAVINGS (PLATINUM)",
      //             "Quarterly": 3832.40,
      //             "SemiAnnual": 7386.08
      //           },
      //           {
      //             "Annual": 880.00,
      //             "Monthly": 85.80,
      //             "Plan": "ACCIDENTAL DEATH & DISMEMBERMENT",
      //             "Quarterly": 242.00,
      //             "SemiAnnual": 466.40
      //           },
      //           {
      //             "Annual": 297.80,
      //             "Monthly": 29.04,
      //             "Plan": "WAIVER OF PREMIUM DUE TO DISABILITY-10PAY",
      //             "Quarterly": 81.90,
      //             "SemiAnnual": 157.83
      //           },
      //           {
      //             "Annual": 0.00,
      //             "Monthly": 0.00,
      //             "Plan": "TERMINAL ILLNESS LIVING BENEFIT",
      //             "Quarterly": 0.00,
      //             "SemiAnnual": 0.00
      //           },
      //           {
      //             "Annual": 15113.80,
      //             "Monthly": 1473.60,
      //             "Plan": "SUBTOTAL",
      //             "Quarterly": 4156.30,
      //             "SemiAnnual": 8010.31
      //           },
      //           {
      //             "Annual": 15113.80,
      //             "Monthly": 1473.60,
      //             "Plan": "TOTAL",
      //             "Quarterly": 4156.30,
      //             "SemiAnnual": 8010.31
      //           },
      //           {
      //             "Annual": 15113.80,
      //             "Monthly": 1473.60,
      //             "Plan": "CURRENT PREMIUM",
      //             "Quarterly": 4156.30,
      //             "SemiAnnual": 8010.31
      //           }
      //         ],
      //         "TotalCount": 7
      //       },
      //       "RequestedOn": "1/27/2022 2:30:59 AM",
      //       "Response": {
      //         "Code": "200",
      //         "Message": "Successful."
      //       },
      //       "Success": true
      //     }
      //   }
      // }
      // // console.log("premiumBreakDown", premiumBreakDown)

      // let newPremiumBreakdownDataList = [];
      // let newPremiumBreakdown = {};

      // premiumBreakDown.data.getPremiumBreakdownResult.Data.PremiumBreakdownList.map((item, index) => {
      //   item.Annual = item.Annual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      //   item.Monthly = item.Monthly.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      //   item.Quarterly = item.Quarterly.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      //   item.SemiAnnual = item.SemiAnnual.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

      //   if (item.Plan != 'SUBTOTAL' && item.Plan != 'TOTAL' && item.Plan != 'CURRENT PREMIUM') {
      //     // if (item.Plan != 'SUBTOTAL') {
      //     if (newPremiumBreakdownDataList.length > 0) {
      //       newPremiumBreakdownDataList = [...newPremiumBreakdownDataList, item]
      //     } else {
      //       newPremiumBreakdownDataList = [item]
      //     }
      //   } else if (item.Plan === 'SUBTOTAL') {
      //     newPremiumBreakdown.subtotal = item;
      //   } else if (item.Plan === 'TOTAL') {
      //     newPremiumBreakdown.total = item;
      //   } else if (item.Plan === 'CURRENT PREMIUM') {
      //     newPremiumBreakdown.currentPremium = item;
      //   }
      // })

      // setPremiumBreakDownDataList(newPremiumBreakdownDataList);
      // setPremiumBreakDown(newPremiumBreakdown);


      // let coverageDetails = {
      //   data: {
      //     "getCoverageDetailsResult": {
      //       "Data": {
      //         "CoverageDetailsList": [
      //           {
      //             "FaceAmount": 100000,
      //             "Maturity": "1/9/2093 12:00:00 AM",
      //             "PayEnd": "10/9/2021 12:00:00 AM",
      //             "Plan": "FUTURE SAVINGS (PLATINUM)",
      //             "Premium": 3832.40,
      //             "Status": "INFORCE"
      //           },
      //           {
      //             "FaceAmount": 200000,
      //             "Maturity": "1/9/2058 12:00:00 AM",
      //             "PayEnd": "10/9/2021 12:00:00 AM",
      //             "Plan": "ACCIDENTAL DEATH & DISMEMBERMENT",
      //             "Premium": 242,
      //             "Status": "INFORCE"
      //           },
      //           {
      //             "FaceAmount": 200000,
      //             "Maturity": "1/9/2025 12:00:00 AM",
      //             "PayEnd": "10/9/2021 12:00:00 AM",
      //             "Plan": "WAIVER OF PREMIUM DUE TO DISABILITY-10PAY",
      //             "Premium": 81.90,
      //             "Status": "INFORCE"
      //           },
      //           {
      //             "FaceAmount": 100000,
      //             "Maturity": "1/9/2058 12:00:00 AM",
      //             "PayEnd": "1/9/2016 12:00:00 AM",
      //             "Plan": "TERMINAL ILLNESS LIVING BENEFIT",
      //             "Premium": 0,
      //             "Status": "FULLY PAID UP"
      //           }
      //         ],
      //         "TotalCount": 4
      //       },
      //       "RequestedOn": "1/27/2022 2:18:13 AM",
      //       "Response": {
      //         "Code": "200",
      //         "Message": "Successful."
      //       },
      //       "Success": true
      //     }
      //   }
      // }
      // // console.log("coverageDetails", coverageDetails)

      // coverageDetails.data.getCoverageDetailsResult.Data.CoverageDetailsList.map((item, index) => {
      //   item.Maturity = moment(item.Maturity, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
      //   item.PayEnd = moment(item.PayEnd, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
      //   item.FaceAmount = item.FaceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      //   item.Premium = item.Premium.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      // })

      // setCoverageDetails(coverageDetails.data.getCoverageDetailsResult.Data.CoverageDetailsList);



      // let benefitsScheduleTableData = {
      //   data: {
      //     "getBenefitScheduleResult": {
      //       "Data": {
      //         "EffectiveDate": "10/5/2017 12:00:00 AM",
      //         "MaturityDate": "5/1/2040 12:00:00 AM",
      //         "PlanType": "K12T051",
      //         "PolicyCurrency": "PHP",
      //         "PolicyDiscrepancy": "Advanced by exactly one year",
      //         "SOB": [
      //           {
      //             "DueDates": "May 01, 2035 and Aug 01, 2035 and Dec 01, 2035",
      //             "GuaranteedCashBenefits": 66500
      //           },
      //           {
      //             "DueDates": "May 01, 2036 and Aug 01, 2036 and Dec 01, 2036",
      //             "GuaranteedCashBenefits": 76475
      //           },
      //           {
      //             "DueDates": "May 01, 2037 and Aug 01, 2037 and Dec 01, 2037",
      //             "GuaranteedCashBenefits": 87941.50
      //           },
      //           {
      //             "DueDates": "May 01, 2038 and Aug 01, 2038 and Dec 01, 2038",
      //             "GuaranteedCashBenefits": 101137
      //           },
      //           {
      //             "DueDates": "Graduation Gift - May 01, 2039",
      //             "GuaranteedCashBenefits": 190000
      //           }
      //         ],
      //         "TotalCashBenefit": 522053.50,
      //         "TotalCountSOB": 5,
      //         "Units": 0,
      //         "YearsToMature": 18
      //       },
      //       "RequestedOn": "1/27/2022 2:32:57 AM",
      //       "Response": {
      //         "Code": "200",
      //         "Message": "Successful."
      //       },
      //       "Success": true
      //     }
      //   }
      // }

      // benefitsScheduleTableData.data.getBenefitScheduleResult.Data.SOB.map((item, index) => {
      //   item.GuaranteedCashBenefits = item.GuaranteedCashBenefits.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      // })

      // // console.log("benefitsScheduleTableData", benefitsScheduleTableData)
      // setBenefitsSchedule(benefitsScheduleTableData.data.getBenefitScheduleResult.Data)
      // setBenefitsScheduleTableData(benefitsScheduleTableData.data.getBenefitScheduleResult.Data.SOB);

    }
    init()
  }, [])
  const PREM_COLUMNS_TRAD = [
    {
      title: "Plan",
      field: "Plan",
      headerStyle: {
        borderRight: '1px solid'
      },
      cellStyle: {
        borderRight: '1px solid'
      },
    },
    {
      title: "Annual",
      field: "Annual",
      // align: 'right',
      headerStyle: {
        borderRight: '1px solid'
      },
      cellStyle: {
        borderRight: '1px solid',
        textAlign: 'right'
      }
    },
    {
      title: "Semi-Annual",
      field: "SemiAnnual",
      // align: 'right',
      headerStyle: {
        borderRight: '1px solid'
      },
      cellStyle: {
        borderRight: '1px solid',
        textAlign: 'right'
      }
    },
    {
      title: "Quarterly",
      field: "Quarterly",
      // align: 'right',
      headerStyle: {
        borderRight: '1px solid'
      },
      cellStyle: {
        borderRight: '1px solid',
        textAlign: 'right'
      }
    },
    {
      title: "Monthly",
      field: "Monthly",
      cellStyle: {
        borderRight: '1px solid',
        textAlign: 'right'
      }
      // align: 'right',
    },
  ]
  const PREM_COLUMNS_VL = [
    {
      title: "Plan",
      field: "Plan",
      headerStyle: {
        borderRight: '1px solid'
      },
      cellStyle: {
        borderRight: '1px solid'
      },
    },
    {
      title: "Annual",
      field: "Annual",
      // align: 'right',
      headerStyle: {
        borderRight: '1px solid'
      },
      cellStyle: {
        borderRight: '1px solid',
        textAlign: 'right'
      }
    },
    {
      title: "Semi-Annual",
      field: "SemiAnnual",
      // align: 'right',
      headerStyle: {
        borderRight: '1px solid'
      },
      cellStyle: {
        borderRight: '1px solid',
        textAlign: 'right'
      }
    },
    {
      title: "Quarterly",
      field: "Quarterly",
      // align: 'right',
      cellStyle: {
        textAlign: 'right'
      }
    }
  ]
  return (
    <Box>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />
      <Typography variant="h4" sx={{ mb: 2 }} color="text.primary">
        Coverages{" "}
      </Typography>
      {/* <Typography variant="body1" color="text.secondary" sx={{ mb: 5 }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </Typography> */}


      <APILoadingOverlay customlabel={loadingCustomLabel} open={isLoading} setOnParent={(value) => setIsLoading(value)}>
        <Typography variant="h6">Coverage Details </Typography>

        <Box sx={{ mt: 2 }}>
          {/* <Typography variant="overline">Policies</Typography> */}

          {coverageDetails.length > 0 ?
            <MaterialUITable
              data={coverageDetails}
              isLoading={false}
              totalCount={coverageDetails.length}
              currentPage={pageNumber}
              rowsPerPage={rowsPerPage}
              columns={[
                {
                  title: "Plan",
                  field: "Plan",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Premium",
                  field: "Premium",
                  // align: 'right',
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  }
                },
                {
                  title: "Face Amount",
                  field: "FaceAmount",
                  // align: 'right',
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  }
                },
                {
                  title: "Maturity",
                  field: "Maturity",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Pay End",
                  field: "PayEnd",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Status",
                  field: "Status",
                },
              ]}
              changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              changePage={(page, pNumber) => setPageNumber(page)}
            />
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}
        </Box>


        <Typography variant="h5" sx={{ mt: 8 }}>Premium Breakdowns</Typography>

        <Box sx={{ mt: 2 }}>
          {/* <Typography variant="overline">Policies</Typography> */}

          {premiumBreakDownDataList.length > 0 ?
            <>

              <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                  <Typography variant="h6" color="text.primary">
                    Total
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ ml: 1 }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle2" color="text.subtitle2">
                      Annual
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {premiumBreakDown?.total?.Annual}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle2" color="text.subtitle2">
                      SemiAnnual
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {premiumBreakDown?.total?.SemiAnnual}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle2" color="text.subtitle2">
                      Quarterly
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {premiumBreakDown?.total?.Quarterly}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle2" color="text.subtitle2">
                      Monthly
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {premiumBreakDown?.total?.Monthly}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }}>
                  <Typography variant="h6" color="text.primary">
                    Current Premium
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ ml: 1 }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle2" color="text.subtitle2">
                      Annual
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {premiumBreakDown?.currentPremium?.Annual}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle2" color="text.subtitle2">
                      SemiAnnual
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {premiumBreakDown?.currentPremium?.SemiAnnual}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle2" color="text.subtitle2">
                      Quarterly
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {premiumBreakDown?.currentPremium?.Quarterly}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography variant="subtitle2" color="text.subtitle2">
                      Monthly
                    </Typography>
                    <Typography variant="h6" color="text.primary">
                      {premiumBreakDown?.currentPremium?.Monthly}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <MaterialUITable
                data={premiumBreakDownDataList}
                isLoading={false}
                totalCount={premiumBreakDownDataList.length}
                currentPage={pageNumber}
                rowsPerPage={rowsPerPage}
                columns={IS_VL ? PREM_COLUMNS_VL : PREM_COLUMNS_TRAD}
                changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                changePage={(page, pNumber) => setPageNumber(page)}
              />
            </>
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}

        </Box>
        {
          !IS_VL && <>
            <Typography variant="h5" sx={{ mt: 8 }}>Benefits Schedule </Typography>

            <Box sx={{ mt: 1, }}>

              {benefitsScheduleTableData.length > 0 ?
                <>
                  {/* <Typography variant="overline">Policies</Typography> */}
                  < Grid container spacing={2} sx={{ mb: 4 }}>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle2" color="text.subtitle2">
                        Policy Number
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {POLICY_DATA}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle2" color="text.subtitle2">
                        Plan Type
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {benefitsSchedule.PlanType}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle2" color="text.subtitle2">
                        Units
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {(benefitsSchedule.Units === '' ||
                          benefitsSchedule.Units === null)
                          ? "--" : benefitsSchedule.Units}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle2" color="text.subtitle2">
                        Effective Date
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {moment(benefitsSchedule.EffectiveDate, "MM/DD/YYYY hh:mm:ss a").isValid() ? moment(benefitsSchedule.EffectiveDate, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY') : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle2" color="text.subtitle2">
                        Maturity Date
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {moment(benefitsSchedule.MaturityDate, "MM/DD/YYYY hh:mm:ss a").isValid() ? moment(benefitsSchedule.MaturityDate, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY') : ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle2" color="text.subtitle2">
                        Years To Mature
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {(benefitsSchedule.YearsToMature === '' ||
                          benefitsSchedule.YearsToMature === null)
                          ? "--" : benefitsSchedule.YearsToMature}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle2" color="text.subtitle2">
                        Policy Currency
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {benefitsSchedule.PolicyCurrency}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography variant="subtitle2" color="text.subtitle2">
                        PolicyDiscrepancy
                      </Typography>
                      <Typography variant="h6" color="text.primary">
                        {(benefitsSchedule.PolicyDiscrepancy === '' ||
                          benefitsSchedule.PolicyDiscrepancy === null)
                          ? "--" : benefitsSchedule.PolicyDiscrepancy}
                      </Typography>
                    </Grid>
                  </Grid>
                  <MaterialUITable
                    data={benefitsScheduleTableData}
                    isLoading={false}
                    totalCount={benefitsScheduleTableData.length}
                    currentPage={pageNumber}
                    rowsPerPage={rowsPerPage}
                    columns={[
                      {
                        title: "Due Dates",
                        field: "DueDates",
                        headerStyle: {
                          borderRight: '1px solid'
                        },
                        cellStyle: {
                          borderRight: '1px solid'
                        },
                      },
                      {
                        title: "Guaranteed Cash Benefits",
                        field: "GuaranteedCashBenefits"
                      },
                    ]}
                    changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                    changePage={(page, pNumber) => setPageNumber(page)}
                  />
                </>
                :
                <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
                  No Data Available
                </Typography>}
            </Box>
          </>
        }
      </APILoadingOverlay>

    </Box >
  );
}


