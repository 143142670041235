import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearPolicyNumber, updatePolicyNumber } from "../features/Theming/PolicyNumberSlice";
import { Button, Typography } from "@mui/material";
import axios from 'axios';
import { updatePolicyDetails } from "../features/Theming/PolicyNumberSlice";

export default function UpdatePolicyButton({ newPolicyNumber }) {
  let dispatch = useDispatch();
  // const policyNumberData = useSelector((state) => state.policyNumber.value);
  // const isVL = useSelector((state) => state.policyNumber.isVL)
  const updateThePolicy = async () => {
    // console.log("New Policy: " + newPolicyNumber);
    // console.log("Old Policy: " + policyNumberData);
    // console.log(isVL);

    await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
      policyNumber: newPolicyNumber,
    })
      .then(async (res) => {
        console.log("getPolicyProfile res.data.Data", res.data.Data);

        if (res === 'null') {

          console.log('issue', 'getPolicyProfile res is null')
          return
        } else if (res.data.Success) {
          // dispatch(updatePolicyNumber(newPolicyNumber));
          let isVL = res.data.Data?.ProductType === 'VL'
          let IsTermPlan = res.data.Data?.IsTermPlan === 'True'

          let policyDetails = {
            policyNumber: newPolicyNumber,
            isVL: isVL,
            isTermPlan: IsTermPlan,
          }
          dispatch(updatePolicyDetails(policyDetails));

        } else {

          console.log('issue', 'getPolicyProfile res', res.data.Response)
          return
        }
      })
  };

  return (
    <Button
      variant="contained"
      onClick={updateThePolicy}
    >
      Select
    </Button>)
}
