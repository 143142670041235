import React, {
  useState,
  useEffect,
} from 'react';

import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";

import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";

import cocolife from "../assets/cocolife-horizontal.png";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import APIErrorModal from '../components/APIErrorModal';

import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import CryptoJS from 'crypto-js'

import axios from "axios";
import moment from "moment-timezone";
import * as Yup from 'yup';
import { setFirebaseUID, setProviderEmail, setProvider, trackOldUserID, updatePage1, updatePage2 } from "../features/Registration/RegistrationSlice";
import { useFormik, useField, useFormikContext } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router';
import { LeakRemoveTwoTone } from '@mui/icons-material';
import { auth, firestoreDB } from "../Firebase/firebase";
import { useAuth } from "../FirebaseAuth";
import { collection, addDoc, getDocs, setDoc, doc, deleteDoc } from "firebase/firestore";
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 80,
  maxWidth: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function UserRegistration2() {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const { firebaseLogout, firebaseSignup, firebaseLogin } = useAuth()

  const mobileNumber = useSelector((state) => state.registration?.mobileNumber)
  const registrationSTATE = useSelector((state) => state.registration)
  const page1 = useSelector((state) => state.registration.page1)
  const page2 = useSelector((state) => state.registration.page2)
  const oldUserIDSTATE = useSelector((state) => state.registration?.oldUserID);
  const PROVIDER_STATE = useSelector((state) => state.registration.provider);
  const PROVIDER_EMAIL_STATE = useSelector((state) => state.registration.providerEmail);
  const FIREBASE_UID_STATE = useSelector((state) => state.registration.firebaseUID);

  const [emailVerified, setEmailVerified] = useState(false)
  const [mobileVerified, setMobileVerified] = useState(false)
  const [geoData, setGeoData] = useState({})

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);
  const [mfaTableID, setmfaTableID] = useState(null);
  const [modalErrorOTP, setModalErrorOTP] = useState(false);
  const [modalExpiredMobileOTP, setModalExpiredMobileOTP] = useState(false);
  const [modalExpiredEmailOTP, setModalExpiredEmailOTP] = useState(false);
  const [modalRequireOTPVerif, setModalRequireOTPVerif] = useState(false);
  const [modalSuccessfullReg, setModalSuccessfullReg] = useState(false);
  const [moodalUsedDifferentSocialiteAccount, setModalUsedDifferentSocialiteAccount] = useState(false);
  const [modalRegisteredWithUnknownProvider, setModalRegisteredWithUnknownProvider] = useState(false);
  const [modalRegistrationIssue, setModalRegistrationIssue] = useState(false);
  const [modalFirebaseError, setModalFirebaseError] = useState(false);

  const [firebaseError, setFirebaseError] = useState('');

  const [otpTmp, setotpTmp] = useState('');
  const [emailOtpTmp, setemailOtpTmp] = useState('');

  const [otpSelected, setotpSelected] = useState('sms');

  const [timerOnState, putTimerOnState] = useState();
  const [timerOnState2, putTimerOnState2] = useState();
  const [seconds, setSeconds] = useState(30);
  const [seconds2, setSeconds2] = useState(30);
  const [timerIsActive, setTimerIsActive] = useState(false);
  const [timerIsActive2, setTimerIsActive2] = useState(false);
  const [smsOTPexpirationDate, setsmsOTPexpirationDate] = useState(moment().add(300, 'seconds'));
  const [emailOTPexpirationDate, setEmailOTPexpirationDate] = useState(moment().add(300, 'seconds'));

  const [regiSchema, setRegiSchema] = useState(
    Yup.object().shape({
      otp: Yup.string()
        .required('Mobile OTP is required'),
      emailOtp: Yup.string()
        .required('Email OTP is required'),
    })
  );

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function init() {
      // moment.tz.setDefault("Asia/Manila");
      const geoData = await axios.get('https://geolocation-db.com/json/')
      console.log("geoData", geoData)
      setGeoData(geoData);
      // moment.tz.setDefault("America/Los_Angeles");
    }
    init()
  }, [])

  useEffect(() => {
    async function init() {
      console.log("FIREBASE_UID_STATE", FIREBASE_UID_STATE)

      let regLogJsonReq = {
        type: "Registration",
        step: "3/6 Validating User Details",
        issue: "",
        description: "",
        status: 'F',
        policyNumber: registrationSTATE.policyNumber,
        firstName: registrationSTATE.firstName,
        middleName: registrationSTATE.middleName,
        lastName: registrationSTATE.lastName,
        suffix: registrationSTATE.suffix,
        birthday: registrationSTATE.birthday,
        email: registrationSTATE.emailAddress,
        mobileNumber: registrationSTATE.mobileNumber,
        provider: PROVIDER_STATE,
        sourceDev: "MyPolicy Website",
        sourceGroup: "MyPolicy Website",
      }

      if (modalSuccessfullReg === false && isLoading === false) {
        if (registrationSTATE.emailAddress == null || registrationSTATE.emailAddress == '') {
          navigate("/")
          regLogJsonReq.issue = "User has no email input";
          await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
        } else if (registrationSTATE.policyNumber == null || registrationSTATE.policyNumber == '') {
          regLogJsonReq.issue = "User has no Policy Number input";
          await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
          navigate("/")
        } else if (PROVIDER_STATE == null || PROVIDER_STATE == '') {
          regLogJsonReq.issue = "User has no selected Provider";
          await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
          navigate("/")
        } else {
          console.log("registrationSTATE:", registrationSTATE);
        }
      }
    }
    init()
  }, [registrationSTATE])

  useEffect(() => {
    async function init() {
      if (process.env.REACT_APP_ENV === 'local') {
        setEmailVerified(true)
        setMobileVerified(true)
        setotpTmp('asd')
        setemailOtpTmp('asd')
      } else {
        sendMFAOTP('sms')
        sendEmailOTP()
      }
    }
    init()
  }, [])

  useEffect(() => {
    if (timerIsActive) {
      // console.log('timerIsActive', timerIsActive)
      const timer = setInterval(() => {
        // console.log('seconds', seconds)
        setSeconds((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
      }, 1000);

      if (seconds <= 0) {
        console.log('timer done')
        setSeconds(30)
        setTimerIsActive(false)
      }

      putTimerOnState(timer);

      return () => {
        clearInterval(timer);
      };
    }
  }, [seconds, timerIsActive])

  useEffect(() => {
    if (timerIsActive2) {
      // console.log('timerIsActive2', timerIsActive2)
      const timer2 = setInterval(() => {
        // console.log('seconds', seconds)
        setSeconds2((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
      }, 1000);

      if (seconds2 <= 0) {
        console.log('timer2 done')
        setSeconds2(30)
        setTimerIsActive2(false)
      }

      putTimerOnState2(timer2);

      return () => {
        clearInterval(timer2);
      };
    }
  }, [seconds2, timerIsActive2])

  // useEffect(() => {
  //   console.log('timerIsActive', timerIsActive)
  //   const timer2 = setInterval(() => {
  //     console.log('seconds2', seconds2)
  //     setSeconds2((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 1));
  //   }, 1000);

  //   // if (seconds2 <= 0) {
  //   //   console.log('seconds <= 0')
  //   //   setSeconds2(30)
  //   //   2(false)
  //   // }

  //   // putTimerOnState(timer2);

  //   return () => {
  //     clearInterval(timer2);
  //   };
  // }, [seconds2])

  const clearStore = () => {
    try {
      dispatch(updatePage1({}))
      dispatch(updatePage2({}))
      dispatch(trackOldUserID(''));
      dispatch(setProvider(''));
      dispatch(setFirebaseUID(''));
      dispatch(setProviderEmail(''));
    } catch (error) {
      console.log("redux error", error);
    }
  }

  const sendMFAOTP = async (type = 'sms') => {
    console.log('called sendMFAOTP');
    try {
      console.log("createMFAOTP axios call")
      console.log("registrationSTATE.mobileNumber", registrationSTATE.mobileNumber)
      await axios.post(process.env.REACT_APP_API_URL + '/api/createMFAOTP', {
        type,
        target: registrationSTATE.mobileNumber,
      }).then(async (res) => {
        console.log("sendMFAOTP res", res)
        let mfaTableID = res.data.data.mfaTableID;
        console.log("mfaTableID", mfaTableID)
        setmfaTableID(mfaTableID);

        // await sendEmailOTP()
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const verifyMFAOTP = async (type = 'sms') => {
    console.log("formik.values.otp != ''", formik.values.otp != '')

    let regLogJsonReq = {
      type: "Registration",
      step: "3/6 - Validating Mobile OTP",
      issue: "",
      description: "",
      status: 'F',
      policyNumber: registrationSTATE.policyNumber,
      firstName: registrationSTATE.firstName,
      middleName: registrationSTATE.middleName,
      lastName: registrationSTATE.lastName,
      suffix: registrationSTATE.suffix,
      birthday: registrationSTATE.birthday,
      email: registrationSTATE.emailAddress,
      mobileNumber: registrationSTATE.mobileNumber,
      provider: PROVIDER_STATE,
      sourceDev: "MyPolicy Website",
      sourceGroup: "MyPolicy Website",
    }

    if (formik.values.otp.trim() != '' && formik.values.otp != null) {
      if (!moment().isSameOrAfter(smsOTPexpirationDate)) {
        try {
          await axios.post(process.env.REACT_APP_API_URL + '/api/verifyMFAOTP', {
            mfaTableID: mfaTableID,
            otpCode: formik.values.otp,
          }).then(async (res) => {
            console.log("res", res)
            console.log("res.data.result", res.data.result)
            if (res.data.result === 'success') {
              console.log("verifyMFAOTP success")
              const correct = true
              if (correct) {
                // save record
                setIsLoading(false);
                setMobileVerified(true);
              } else {
                // error
                console.log("setIsLoading false")
                setModalErrorOTP(true);

                regLogJsonReq.issue = "Mobile OTP was invalid";
                await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                setIsLoading(false);
              }
              return { success: true }
            } else {
              // sendMFAOTP('sms')
              setModalErrorOTP(true);

              regLogJsonReq.issue = "Mobile OTP was invalid";
              await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

              setIsLoading(false)
              return { success: false }
            }
          })
        } catch (error) {
          console.log("axios error", error);
          setIsLoading(false);
          return { success: false }
          // return {
          //   error: "There was na issue with the MFA. Please contact our customer support."
          // }
        }
      } else {
        setIsLoading(false)
        console.log("moment().isSameOrAfter(smsOTPexpirationDate)", moment().isSameOrAfter(smsOTPexpirationDate))
        setModalExpiredMobileOTP(true)

        regLogJsonReq.issue = "Mobile OTP has expired";
        await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
      }
    } else {
      setIsLoading(false)
      console.log("mobileotp is blank")
      formik.setErrors({ otp: 'Mobile OTP is required' });
    }
  }

  const sendEmailOTP = async (type = 'sms') => {
    console.log('called sendEmailOTP');
    try {
      console.log("sendMail axios call")
      console.log("registrationSTATE.emailAddress", registrationSTATE.emailAddress)
      await axios.post(process.env.REACT_APP_API_URL + '/api/otpGenerator', {
        email: registrationSTATE.emailAddress,
        type: 'verify-email',
      }).then(async (res) => {
        console.log("sendMail res", res)
      })
    } catch (error) {
      console.log("axios error", error);
    }
  }

  const verifyMailOTP = async (type = 'sms') => {
    let regLogJsonReq = {
      type: "Registration",
      step: "3/6 - Validating Email OTP",
      issue: "",
      description: "",
      status: 'F',
      policyNumber: registrationSTATE.policyNumber,
      firstName: registrationSTATE.firstName,
      middleName: registrationSTATE.middleName,
      lastName: registrationSTATE.lastName,
      suffix: registrationSTATE.suffix,
      birthday: registrationSTATE.birthday,
      email: registrationSTATE.emailAddress,
      mobileNumber: registrationSTATE.mobileNumber,
      provider: PROVIDER_STATE,
      sourceDev: "MyPolicy Website",
      sourceGroup: "MyPolicy Website",
    }

    console.log("formik.values.emailOtp", formik.values.emailOtp != '')
    if (formik.values.emailOtp.trim() != '' && formik.values.emailOtp != null) {
      console.log("formik.values.emailOtp.split('').length", formik.values.emailOtp.split('').length)
      if (formik.values.emailOtp.split('').length == 6) {
        if (!moment().isSameOrAfter(emailOTPexpirationDate)) {
          try {
            console.log("sendMail axios call")
            await axios.post(process.env.REACT_APP_API_URL + '/api/otpValidate', {
              token: formik.values.emailOtp,
              email: registrationSTATE.emailAddress,
              type: 'verify-email',
            }).then(async (res) => {
              console.log("sendMail res", res)

              if (res.data.data.Success) {
                console.log("verifyMailOTP success")
                setIsLoading(false);
                setEmailVerified(true)
              } else if (res.data.data.Error == 'Expired OTP') {
                setModalExpiredEmailOTP(true);

                regLogJsonReq.issue = "Email OTP has expired";
                await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                setIsLoading(false)
              } else {
                setModalErrorOTP(true);

                regLogJsonReq.issue = "Email OTP was invalid";
                await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                setIsLoading(false)
              }

            })
          } catch (error) {
            console.log("axios error", error);
            setIsLoading(false);
            return { success: false }
            // return {
            //   error: "There was na issue with the MFA. Please contact our customer support."
            // }
          }
        } else {
          console.log("moment().isSameOrAfter(emailOTPexpirationDate)", moment().isSameOrAfter(emailOTPexpirationDate))
          setModalExpiredEmailOTP(true)

          regLogJsonReq.issue = "Email OTP has expired";
          await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

          setIsLoading(false)
        }
      } else {
        setModalErrorOTP(true);

        regLogJsonReq.issue = "Email OTP was invalid";
        await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

        setIsLoading(false)
      }

    } else {
      setIsLoading(false)
      console.log("emailOtp is blank")
      formik.setErrors({ emailOtp: 'Email OTP is required' });
    }
  }

  // const sendCocolifeSMSOTP = async () => {
  //   try {
  //     return await axios.post(process.env.REACT_APP_API_URL + '/api/vCardSendSMS', {

  //       mobileNo: mobileNumber,
  //       smsBody: 'Welcome to Cocolife! Your registration code is 88073'

  //     }).then((res) => {
  //       console.log("vCardSendSMS res.data.Data", res.data.Data);

  //       if (res === 'null') {
  //         setapiName('vCardSendSMS')
  //         setapiResponse({
  //           Code: '408',
  //           Message: "API request timeout."
  //         })
  //         setAPIErrorModalOpen(true)
  //         console.log('issue', 'vCardSendSMS res is null')
  //         return
  //       }

  //       if (res.data.Success) {

  //         console.log('SMS SENT!!')
  //         return true

  //       } else {
  //         setapiName('vCardSendSMS')
  //         setapiResponse(res.data)
  //         setAPIErrorModalOpen(true)
  //         console.log('issue', 'vCardSendSMS res', res.data.Response)
  //         return
  //       }
  //     })

  //   } catch (error) {
  //     console.log("axios error", error);
  //   }
  // }

  const saveToBackend = async ({
    policyNumber,
    firstName,
    middleName,
    lastName,
    suffix,
    birthday,

    emailAddress,
    mobileNumber,
    policyAgreementDate,
    username,
    password,
    provider
  }) => {
    let emailVerified = false;
    let mobileVerified = false;

    let regLogJsonReq = {
      type: "Registration",
      step: "3/6 - Creating MyPolicy Account Details",
      issue: "",
      description: "",
      status: 'F',
      policyNumber: policyNumber,
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      suffix: suffix,
      birthday: birthday,
      email: emailAddress,
      mobileNumber: mobileNumber,
      provider: provider,
      sourceDev: "MyPolicy Website",
      sourceGroup: "MyPolicy Website",
    }

    try {

      return await axios.post(process.env.REACT_APP_API_URL + '/api/saveRegistrationPage1', {
        policyNumber,
        firstName,
        middleName,
        lastName,
        suffix,
        birthday,

        emailAddress,
        mobileNumber,
        policyAgreementDate,

        emailVerified: true,
        mobileVerified: true,

        provider,

        username,
        password,
        oldUserID: oldUserIDSTATE,
        geoData: geoData,

      }).then(async (saveRegistrationPage1Res) => {
        console.log("saveRegistrationPage1 saveRegistrationPage1Res.data", saveRegistrationPage1Res.data);

        if (saveRegistrationPage1Res == null) {
          console.log('issue', 'saveRegistrationPage1 saveRegistrationPage1Res is null')

          // do roll back
          if (auth.currentUser != null) {
            // await auth.currentUser.delete().then((res2) => {
            //   console.log("deleted user", res2)
            // })

            firebaseLogout()
              .then(async (firebaseResponse) => {
                console.log("successful logout from firebase")

              }).catch((error) => {
                console.error("unsuccessful logout from firebase:", error);
              })
          }

          setapiName('validatePolicy')
          setapiResponse({
            Code: '408',
            Message: "API request timeout."
          })
          setAPIErrorModalOpen(true)
          setIsLoading(false);
        } else if (saveRegistrationPage1Res.data.APIName != null) {
          console.log('issue', 'saveRegistrationPage1 API error', saveRegistrationPage1Res)

          // do roll back
          if (auth.currentUser != null) {
            // await auth.currentUser.delete().then((res2) => {
            //   console.log("deleted user", res2)
            // })

            firebaseLogout()
              .then(async (firebaseResponse) => {
                console.log("successful logout from firebase")

              }).catch((error) => {
                console.error("unsuccessful logout from firebase:", error);
              })
          }

          console.log("setIsLoading false")
          setapiName(saveRegistrationPage1Res.data.APIName)
          setapiResponse(saveRegistrationPage1Res.data.Response)
          setAPIErrorModalOpen(true)
          setIsLoading(false);
        } else if (saveRegistrationPage1Res.data.data.Success != true) {
          console.log('issue', 'saveRegistrationPage1 saveRegistrationPage1Res is false')
          // await auth.currentUser.delete().then((res) => {
          //   console.log("deleted user", res)
          // })

          // do roll back
          if (auth.currentUser != null) {
            // await auth.currentUser.delete().then((res2) => {
            //   console.log("deleted user", res2)
            // })

            firebaseLogout()
              .then(async (firebaseResponse) => {
                console.log("successful logout from firebase")

              }).catch((error) => {
                console.error("unsuccessful logout from firebase:", error);
              })
          }

          setIsLoading(false);
          setModalRegistrationIssue(true)
        } else {
          console.log('saveRegistrationPage1Res success', saveRegistrationPage1Res)

          return await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByEmail', {
            email: emailAddress,
          }).then(async (getUserByEmailRes) => {
            console.log("getUserByEmailRes", getUserByEmailRes)

            if (getUserByEmailRes.data.user === false) {
              console.log("getUserByEmailRes fail")

              regLogJsonReq.issue = "MyPolicy User Creation Failed - R.2";
              regLogJsonReq.description = emailAddress + " account was not found within MS SQL DB";
              await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

              // do roll back
              if (auth.currentUser != null) {
                // await auth.currentUser.delete().then((res2) => {
                //   console.log("deleted user", res2)
                // })

                firebaseLogout()
                  .then(async (firebaseResponse) => {
                    console.log("successful logout from firebase")

                  }).catch((error) => {
                    console.error("unsuccessful logout from firebase:", error);
                  })
              }

              setIsLoading(false);
              setModalRegistrationIssue(true)
            } else {
              console.log("getUserByEmailRes success")

              if (registrationSTATE.oldUserID != null && registrationSTATE.oldUserID != '') {
                // Deactivate Old Cocolife Account    
                await axios.post(process.env.REACT_APP_API_URL + '/api/deactivateOldUser', {
                  oldUserID: oldUserIDSTATE,
                }).then(async (res) => {
                  console.log("deactivateOldUser res", res)
                  console.log("deactivated user_id's", oldUserIDSTATE)
                })

                console.log("registrationSTATE.oldUsername", registrationSTATE.oldUsername)
                console.log("registrationSTATE.oldPassword", registrationSTATE.oldPassword)
                await axios.post(process.env.REACT_APP_API_URL + '/api/deactivateOldAccount', {
                  username: registrationSTATE.oldUsername,
                  password: registrationSTATE.oldPassword
                }).then(async (res) => {
                  console.log("deactivateOldAccount res.data.Data", res.data.Data);

                  if (res === 'null') {
                    // do roll back
                    regLogJsonReq.step = "4/6 - Deactivating Old Account via API";
                    regLogJsonReq.issue = "API 'deactivateOldAccount' failed to respond";
                    await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                    if (auth.currentUser != null) {
                      await auth.currentUser.delete().then((res2) => {
                        console.log("deleted user", res2)
                      })

                      firebaseLogout()
                        .then(async (firebaseResponse) => {
                          console.log("successful logout from firebase")

                        }).catch((error) => {
                          console.error("unsuccessful logout from firebase:", error);
                        })
                    }

                    await axios.post(process.env.REACT_APP_API_URL + '/api/cancelRegistration', {
                      user_id: getUserByEmailRes.data.user.recordset[0].user_id.toLowerCase(),
                    }).then(async (res) => {
                      console.log("cancelled user_id's", getUserByEmailRes.data.user.recordset[0].user_id, "registration", res)
                    })

                    setapiName('deactivateOldAccount')
                    setapiResponse({
                      Code: '408',
                      Message: "API request timeout."
                    })
                    setAPIErrorModalOpen(true)
                    setIsLoading(false)
                    console.log('issue', 'deactivateOldAccount res is null')
                    return
                  } else if (res.data.Success) {

                    let passwordHash = '';
                    if (provider === 'emailpassword') {
                      passwordHash = CryptoJS.SHA256(password).toString();
                      console.log('passwordHash', passwordHash)
                      // console.log('passwordHash.toString()', passwordHash.toString())
                    } else {
                      passwordHash = null;
                    }

                    console.log('auth.currentUser', auth.currentUser)
                    console.log('saving Firebase user details now')

                    return await setDoc(doc(firestoreDB, "Table_User", auth.currentUser.uid), {
                      policy_number: policyNumber,
                      first_name: firstName,
                      middle_name: middleName,
                      last_name: lastName,
                      suffix: suffix,
                      birthday: birthday,
                      email: emailAddress,
                      mobile_number: mobileNumber,
                      username: username,
                      password: passwordHash,
                      provider: provider,
                      email_verified: true,
                      mobile_verified: true,
                      firebase_uid: auth.currentUser.uid,
                      user_id: getUserByEmailRes.data.user.recordset[0].user_id.toLowerCase(),
                      latest_ipv4: "",
                      signup_ipv4: "",
                      country_name: "",
                      created_at: moment().format('MMMM DD, YYYY at hh:mm:ss a Z'),
                      updated_at: moment().format('MMMM DD, YYYY at hh:mm:ss a Z'),
                    }).then(async (res) => {
                      console.log('firebase create user res', res)
                      console.log("setIsLoading false")
                      setIsLoading(false);

                      setModalSuccessfullReg(true)
                      clearStore()

                      regLogJsonReq.step = "6/6 - User Created";
                      regLogJsonReq.issue = "";
                      regLogJsonReq.description = "";
                      regLogJsonReq.status = "S";
                      await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
                    }).catch(async (err) => {

                      regLogJsonReq.step = "5/6 - Creating Firebase Record";
                      regLogJsonReq.issue = "Firebase record creation failed";
                      regLogJsonReq.description = "Firebase error: " + JSON.stringify(err);
                      await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                      // do roll back
                      if (auth.currentUser != null) {
                        await deleteDoc(doc(firestoreDB, "Table_User", auth.currentUser.uid));

                        await auth.currentUser.delete().then((res2) => {
                          console.log("deleted user", res2)
                        })

                        firebaseLogout()
                          .then(async (firebaseResponse) => {
                            console.log("successful logout from firebase")

                          }).catch((error) => {
                            console.error("unsuccessful logout from firebase:", error);
                          })

                      }

                      await axios.post(process.env.REACT_APP_API_URL + '/api/cancelRegistration', {
                        user_id: getUserByEmailRes.data.user.recordset[0].user_id.toLowerCase(),
                      }).then(async (res) => {
                        console.log("cancelled user_id's", getUserByEmailRes.data.user.recordset[0].user_id, "registration", res)
                      })

                      console.log('firebase create user issue', err)

                      setIsLoading(false);
                      setModalRegistrationIssue(true)
                    });

                  } else {

                    regLogJsonReq.step = "4/6 - Deactivating Old Account via API";
                    regLogJsonReq.issue = "API 'deactivateOldAccount' Error";
                    regLogJsonReq.description = "API 'deactivateOldAccount' Error: " + res?.data?.Response?.Message;
                    await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                    // do roll back
                    if (auth.currentUser != null) {
                      await auth.currentUser.delete().then((res2) => {
                        console.log("deleted user", res2)
                      })

                      firebaseLogout()
                        .then(async (firebaseResponse) => {
                          console.log("successful logout from firebase")

                        }).catch((error) => {
                          console.error("unsuccessful logout from firebase:", error);
                        })
                    }

                    await axios.post(process.env.REACT_APP_API_URL + '/api/cancelRegistration', {
                      user_id: getUserByEmailRes.data.user.recordset[0].user_id.toLowerCase(),
                    }).then(async (res) => {
                      console.log("cancelled user_id's", getUserByEmailRes.data.user.recordset[0].user_id, "registration", res)
                    })

                    setapiName('deactivateOldAccount')
                    setapiResponse(res.data)
                    setAPIErrorModalOpen(true)

                    setIsLoading(false)
                    console.log('issue', 'deactivateOldAccount res', res.data.Response)
                    return
                  }
                }).catch(async (error) => {
                  // do roll back
                  regLogJsonReq.step = "4/6 - Deactivating Old Account via API";
                  regLogJsonReq.issue = "API 'deactivateOldAccount' Error";
                  regLogJsonReq.description = "API 'deactivateOldAccount' Error: " + JSON.stringify(error);
                  await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                  if (auth.currentUser != null) {
                    await auth.currentUser.delete().then((res2) => {
                      console.log("deleted user", res2)
                    })

                    firebaseLogout()
                      .then(async (firebaseResponse) => {
                        console.log("successful logout from firebase")

                      }).catch((error) => {
                        console.error("unsuccessful logout from firebase:", error);
                      })
                  }

                  await axios.post(process.env.REACT_APP_API_URL + '/api/cancelRegistration', {
                    user_id: getUserByEmailRes.data.user.recordset[0].user_id.toLowerCase(),
                  }).then(async (res) => {
                    console.log("cancelled user_id's", getUserByEmailRes.data.user.recordset[0].user_id, "registration", res)
                  })

                  setapiName('deactivateOldAccount')
                  setapiResponse({
                    Code: error?.response?.status,
                    Message: error?.response?.statusText
                  })
                  setAPIErrorModalOpen(true)
                  setIsLoading(false);
                  console.log('issue', error)
                })
              } else {
                let passwordHash = '';
                if (provider === 'emailpassword') {
                  passwordHash = CryptoJS.SHA256(password).toString();
                  console.log('passwordHash', passwordHash)
                  // console.log('passwordHash.toString()', passwordHash.toString())
                } else {
                  passwordHash = null;
                }

                console.log('auth.currentUser', auth.currentUser)
                console.log('saving Firebase user details now')

                return await setDoc(doc(firestoreDB, "Table_User", auth.currentUser.uid), {
                  policy_number: policyNumber,
                  first_name: firstName,
                  middle_name: middleName,
                  last_name: lastName,
                  suffix: suffix,
                  birthday: birthday,
                  email: emailAddress,
                  mobile_number: mobileNumber,
                  username: username,
                  password: passwordHash,
                  provider: provider,
                  email_verified: true,
                  mobile_verified: true,
                  firebase_uid: auth.currentUser.uid,
                  user_id: getUserByEmailRes.data.user.recordset[0].user_id.toLowerCase(),
                  latest_ipv4: "",
                  signup_ipv4: "",
                  country_name: "",
                  created_at: moment().format('MMMM DD, YYYY at hh:mm:ss a Z'),
                  updated_at: moment().format('MMMM DD, YYYY at hh:mm:ss a Z'),
                }).then(async (res) => {
                  console.log('firebase create user res', res)
                  console.log("setIsLoading false")
                  setIsLoading(false);

                  setModalSuccessfullReg(true)
                  clearStore()

                  regLogJsonReq.step = "6/6 - User Created";
                  regLogJsonReq.issue = "";
                  regLogJsonReq.description = "";
                  regLogJsonReq.status = "S";
                  await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)
                }).catch(async (err) => {

                  regLogJsonReq.step = "5/6 - Creating Firebase Record";
                  regLogJsonReq.issue = "Firebase record creation failed";
                  regLogJsonReq.description = "Firebase error: " + JSON.stringify(err);
                  await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                  // do roll back
                  if (auth.currentUser != null) {
                    await deleteDoc(doc(firestoreDB, "Table_User", auth.currentUser.uid));

                    await auth.currentUser.delete().then((res2) => {
                      console.log("deleted user", res2)
                    })

                    firebaseLogout()
                      .then(async (firebaseResponse) => {
                        console.log("successful logout from firebase")

                      }).catch((error) => {
                        console.error("unsuccessful logout from firebase:", error);
                      })

                  }

                  await axios.post(process.env.REACT_APP_API_URL + '/api/cancelRegistration', {
                    user_id: getUserByEmailRes.data.user.recordset[0].user_id.toLowerCase(),
                  }).then(async (res) => {
                    console.log("cancelled user_id's", getUserByEmailRes.data.user.recordset[0].user_id, "registration", res)
                  })

                  console.log('firebase create user issue', err)

                  setIsLoading(false);
                  setModalRegistrationIssue(true)
                });
              }

            }
          }).catch(async (error) => {
            console.log('getUserByEmail error', error)

            regLogJsonReq.issue = "MyPolicy User Creation Failed - R.1";
            regLogJsonReq.description = emailAddress + " account was not found within MS SQL DB. Error " + JSON.stringify(error);
            await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

            // do roll back
            if (auth.currentUser != null) {
              // await auth.currentUser.delete().then((res2) => {
              //   console.log("deleted user", res2)
              // })

              firebaseLogout()
                .then(async (firebaseResponse) => {
                  console.log("successful logout from firebase")

                }).catch((error) => {
                  console.error("unsuccessful logout from firebase:", error);
                })
            }

            setIsLoading(false);
            setModalRegistrationIssue(true)
          })

        }
      })

    } catch (error) {
      // do roll back
      console.log("axios error", error);
      // do roll back
      if (auth.currentUser != null) {
        // await auth.currentUser.delete().then((res2) => {
        //   console.log("deleted user", res2)
        // })

        firebaseLogout()
          .then(async (firebaseResponse) => {
            console.log("successful logout from firebase")

          }).catch((error) => {
            console.error("unsuccessful logout from firebase:", error);
          })
      }

      setIsLoading(false);
      setModalRegistrationIssue(true)
    }
  }

  const formik = useFormik({
    initialValues: {
      otp: otpTmp,
      emailOtp: emailOtpTmp,
    },
    enableReinitialize: true,
    validationSchema: regiSchema,
    onSubmit: async (values) => {
      console.log("onSubmit called")
      console.log("setIsLoading true")
      setIsLoading(true);

      let regLogJsonReq = {
        type: "Registration",
        step: "2.5/6 Creating Firebase User",
        issue: "",
        description: "",
        status: 'F',
        policyNumber: registrationSTATE.policyNumber,
        firstName: registrationSTATE.firstName,
        middleName: registrationSTATE.middleName,
        lastName: registrationSTATE.lastName,
        suffix: registrationSTATE.suffix,
        birthday: registrationSTATE.birthday,
        email: registrationSTATE.emailAddress,
        mobileNumber: registrationSTATE.mobileNumber,
        provider: PROVIDER_STATE,
        sourceDev: "MyPolicy Website",
        sourceGroup: "MyPolicy Website",
      }

      // regLogJsonReq.issue = "Duplicate Mobile Number";
      // await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

      console.log("moment().isSameOrAfter(smsOTPexpirationDate)", moment().isSameOrAfter(smsOTPexpirationDate))
      if (emailVerified && mobileVerified) {
        console.log("calling saveToBackend")

        console.log("PROVIDER_STATE", PROVIDER_STATE)
        if (PROVIDER_STATE === 'apple' || PROVIDER_STATE === 'facebook' || PROVIDER_STATE === 'google') {
          var socialiteProvider;

          if (PROVIDER_STATE === 'facebook') {
            socialiteProvider = new FacebookAuthProvider();
          } else if (PROVIDER_STATE === 'google') {
            socialiteProvider = new GoogleAuthProvider();
          } else if (PROVIDER_STATE === 'apple') {
            socialiteProvider = new OAuthProvider('apple.com');
          }
          console.log("socialiteProvider", socialiteProvider)

          auth.signInWithPopup(socialiteProvider)
            .then(async (res) => {
              console.log("res.user", res.user)
              console.log("res.user.email", res.user?.email)

              if (res.user.uid === FIREBASE_UID_STATE) {
                auth.currentUser.updateEmail(
                  registrationSTATE.emailAddress
                ).then(async (res) => {
                  console.log("firebase updateEmail success:", res)
                  await saveToBackend(registrationSTATE)
                }).catch(async (err) => {
                  setIsLoading(false);
                  console.log("firebase updateEmail fail:", err)

                  regLogJsonReq.issue = "Firebase Email Update Failed";
                  regLogJsonReq.description = JSON.stringify(err);
                  await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                  // await auth.currentUser.delete().then((res) => {
                  //   console.log("deleted user", res)
                  // })
                  firebaseLogout()
                    .then(async (firebaseResponse) => {
                      console.log("successful logout from firebase")

                    }).catch((error) => {
                      console.error("unsuccessful logout from firebase:", error);
                    })

                  setModalRegisteredWithUnknownProvider(true)
                })
              } else {
                setIsLoading(false);
                setModalUsedDifferentSocialiteAccount(true)

                regLogJsonReq.issue = "Incorrect " + PROVIDER_STATE + " Email provided";
                regLogJsonReq.description = PROVIDER_STATE + " Email must be the same as the " + PROVIDER_STATE + " Email used to Authenticate on the pop-up on the login page";
                await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                // await auth.currentUser.delete().then((res) => {
                //   console.log("deleted user", res)
                // })
                firebaseLogout()
                  .then(async (firebaseResponse) => {
                    console.log("successful logout from firebase")

                  }).catch((error) => {
                    console.error("unsuccessful logout from firebase:", error);
                  })
                console.log("User has used a different socialite account")
                console.log("Firebaseuid from login:", FIREBASE_UID_STATE)
                console.log("Email on Reg:", res.user?.uid)
              }
            })
            .catch(async (err) => {
              setIsLoading(false);
              console.log("err", err)

              regLogJsonReq.issue = "Firebase " + PROVIDER_STATE + " Auth issue";
              regLogJsonReq.description = JSON.stringify(err);
              await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

              if (auth.currentUser != null) {
                // await auth.currentUser.delete().then((res) => {
                //   console.log("deleted user", res)
                // })
              }
              firebaseLogout()
                .then(async (firebaseResponse) => {
                  console.log("successful logout from firebase")

                }).catch((error) => {
                  console.error("unsuccessful logout from firebase:", error);
                })

              setModalRegisteredWithUnknownProvider(true)
            })
        } else {

          // let passwordHash = '';
          // if (provider === 'emailpassword') {
          //   passwordHash = CryptoJS.SHA256(registrationSTATE.password).toString();
          //   console.log('passwordHash', passwordHash)
          // } else {
          //   passwordHash = null;
          // }

          // CHECK IF USER HAS FIREBASE ACCOUNT
          firebaseSignup(registrationSTATE.emailAddress, registrationSTATE.password)
            .then(async (response) => {
              console.log("success creating firebase account")

              // CREATE USER IF USER HAS NO FIREBASE ACCOUNT
              console.log("firebase res", response)

              // SIGN IN WITH THE NEW FIREBASE ACCOUNT
              firebaseLogin(registrationSTATE.emailAddress, registrationSTATE.password)
                .then(async (response) => {
                  // console.log("successful login to firebase")
                  // console.log("firebase response", response)
                  // console.log("firebase response.uid", response.uid)

                  await saveToBackend(registrationSTATE)
                }).catch((error) => {
                  console.log("setIsLoading false")
                  setModalRegistrationIssue(true);
                  setIsLoading(false);
                  console.error("Firebase Login Error:", error);
                })

            }).catch((error) => {
              // IF USER EXISTING HAS A FIREBASE ACCOUNT
              // console.error("Firebase Signup Error:", error);
              console.error("Firebase Signup Error:", error.message);

              // SIGN IN WITH THE EXISTING FIREBASE ACCOUNT
              firebaseLogin(registrationSTATE.emailAddress, registrationSTATE.password)
                .then(async (response) => {
                  // console.log("successful login to firebase")
                  // console.log("firebase response", response)
                  // console.log("firebase response.uid", response.uid)

                  await saveToBackend(registrationSTATE)
                }).catch(async (error) => {
                  // IF USER'S EXISTING ACCOUNT IS A SOCIALITE PROVIDER
                  // ADD PASSWORD AND EMAIL TO ACCOUNT TO LINK IT

                  await axios.post(process.env.REACT_APP_API_URL + '/api/linkPasswordToFirebaseAccount', {
                    email: registrationSTATE.emailAddress,
                    password: registrationSTATE.password,
                  }).then(async (res) => {
                    console.log("linkPasswordToFirebaseAccount res", res)

                    if (res.data.success) {
                      firebaseLogin(registrationSTATE.emailAddress, registrationSTATE.password)
                        .then(async (response) => {
                          // console.log("successful login to firebase")
                          // console.log("firebase response", response)
                          // console.log("firebase response.uid", response.uid)

                          await saveToBackend(registrationSTATE)
                        }).catch(async (error) => {
                          console.log("setIsLoading false")

                          setFirebaseError(error.message)
                          setModalFirebaseError(true)
                          // setModalRegistrationIssue(true);                

                          regLogJsonReq.issue = "User already has an existing Firebase Account based on the Email Provided";
                          regLogJsonReq.description = "Check if the Email used has a MyPolicy Account. If user has a MyPolicy Account, this registration is invalid and outdated. If user has no MyPolicy Account, the Firebase account is an orphan and must be deleted.";
                          await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)

                          setIsLoading(false);
                          console.error("Firebase Login Error:", error);

                        })
                    } else {
                      console.log("setIsLoading false")

                      setFirebaseError(error.message)
                      setModalFirebaseError(true)
                      // setModalRegistrationIssue(true);                

                      regLogJsonReq.issue = "User already has an existing Firebase Account based on the Email Provided";
                      regLogJsonReq.description = "Check if the Email used has a MyPolicy Account. If user has a MyPolicy Account, this registration is invalid and outdated. If user has no MyPolicy Account, the Firebase account is an orphan and must be deleted.";
                      await axios.post(process.env.REACT_APP_API_URL + '/api/createRegistrationLog', regLogJsonReq)


                      setIsLoading(false);
                      console.error("Firebase Login Error:", error);
                    }
                  })
                })
            })
        }
      } else {
        setIsLoading(false);
        setModalRequireOTPVerif(true)
      }

      // console.log({ values otp != '' || })
    }
  })

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: 'background.default' }} >
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalErrorOTP}
        onClose={() => {
          setModalErrorOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalErrorOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The OTP you have entered is incorrect.
              Please enter the correct OTP Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalErrorOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalRequireOTPVerif}
        onClose={() => {
          setModalRequireOTPVerif(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalRequireOTPVerif}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`Please verify both OTP codes first before submitting your registration.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalRequireOTPVerif(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExpiredMobileOTP}
        onClose={() => {
          setModalExpiredMobileOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExpiredMobileOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The Mobile OTP you have entered has expired.
              Click Resend code to receive a new Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalExpiredMobileOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalExpiredEmailOTP}
        onClose={() => {
          setModalExpiredEmailOTP(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalExpiredEmailOTP}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`The Mobile OTP you have entered has expired.
        Click Resend code to receive a new Code.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalExpiredEmailOTP(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSuccessfullReg}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalSuccessfullReg(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSuccessfullReg}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6"
              component="h2" color="text.primary">
              Registration Successful!
            </Typography>

            <Divider sx={{ mt: 3, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {`Please use your registered Email Address to login on the MyPolicy Portal.`}
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 1 }}
              color="text.secondary"
            >
              {`Thank you.`}
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setModalSuccessfullReg(false)
                navigate("/")
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={moodalUsedDifferentSocialiteAccount}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalUsedDifferentSocialiteAccount(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={moodalUsedDifferentSocialiteAccount}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`Please login with the account that you used earlier.`}
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setModalUsedDifferentSocialiteAccount(false)
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalRegisteredWithUnknownProvider}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalRegisteredWithUnknownProvider(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalRegisteredWithUnknownProvider}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`User has tried to login using a different login type.`}
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`Please use the login type that you used to register.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalRegisteredWithUnknownProvider(false)
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalRegistrationIssue}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalRegistrationIssue(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalRegistrationIssue}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`There was an issue with the server. Please try again`}
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {/* {`User has registered with a different login method.`} */}
              {`If the issue keeps repeating please contact our support team.`}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalRegistrationIssue(false)
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalFirebaseError}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalFirebaseError(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalFirebaseError}>
          <Box sx={style}>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {firebaseError}
            </Typography>
            <Button variant="contained"
              onClick={() => {
                setModalFirebaseError(false)
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Container maxWidth="sm" sx={{ p: 2 }}>
        <Paper variant="outlined" sx={{ py: 5, px: 4 }}>
          <Stack spacing={4}>
            <Box>
              <img src={cocolife} style={{ height: "25px" }} />
            </Box>
            <Box>
              <Typography variant="h4" color="text.primary" sx={{ mb: 2 }}>
                User Registration
              </Typography>

              <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }} >
                Only <strong>Cocolife Policy payors</strong> are allowed to register.
              </Typography>
              <Divider sx={{ mt: 1 }} />
            </Box>

            <FormControl sx={{ my: 1, display: "flex", flexDirection: "column", alignItems: "center", }} fullWidth>
              <form onSubmit={formik.handleSubmit}>

                {isLoading ?
                  <>
                    <Typography variant="h6" sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                      Loading...
                    </Typography>
                  </>
                  :
                  <>
                    {mobileVerified ?
                      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', minWidth: 80 }}>
                        Mobile Number successfully Verified!
                      </Typography>
                      :
                      <>
                        <Stack sx={{ alignItems: "center" }}>
                          <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                            Enter the OTP Code that we have sent to your Mobile Number
                          </Typography>

                          <TextField
                            name="otp"
                            variant="outlined"
                            hiddenLabel
                            value={formik.values.otp}
                            placeholder='Mobile OTP Code'
                            onChange={formik.handleChange}
                            sx={{ minWidth: 80, maxWidth: '100%' }}
                          />
                          {formik.errors.otp ? <Typography color="red">{formik.errors.otp}</Typography> : null}
                        </Stack>


                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={3}
                          sx={{ mt: 1 }}
                        >
                          {timerIsActive ?
                            <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                              Resend a new OTP code in {seconds} seconds
                            </Typography>
                            :
                            <Button variant="text" size="large"
                              onClick={() => {
                                setSeconds(30);
                                setTimerIsActive(true);
                                setsmsOTPexpirationDate(moment().add(300, 'seconds'));
                                sendMFAOTP();
                              }}>
                              Resend a new OTP Code to mobile
                            </Button>}
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={3}
                          sx={{ mb: 2 }}
                        >
                          <Typography variant="body1" sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                            Mobile OTP Expiry: {smsOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={3}
                          sx={{ mt: 1 }}
                        >
                          <Button variant="contained"
                            onClick={() => {
                              verifyMFAOTP()
                            }}>
                            Verify Mobile OTP
                          </Button>
                        </Stack>
                      </>
                    }

                    <Divider sx={{ my: 3 }} />

                    {emailVerified ?
                      <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', minWidth: 80 }}>
                        Email Address successfully Verified!
                      </Typography>
                      :
                      <>
                        <Stack sx={{ alignItems: "center" }}>
                          <Typography variant="body1" sx={{ mb: 3, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                            Enter the OTP Code that we have sent to your Email Address
                          </Typography>

                          <TextField
                            name="emailOtp"
                            variant="outlined"
                            hiddenLabel
                            value={formik.values.emailOtp}
                            placeholder='Email OTP Code'
                            onChange={formik.handleChange}
                            sx={{ minWidth: 80, maxWidth: '100%' }}
                          />
                          {formik.errors.emailOtp ? <Typography color="red">{formik.errors.emailOtp}</Typography> : null}
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={3}
                          sx={{ mt: 1 }}
                        >
                          {timerIsActive2 ?
                            <Typography variant="subtitle1" sx={{ mt: 1, textAlign: 'center' }}>
                              Resend a new OTP code in {seconds2} seconds
                            </Typography>
                            :
                            <Button variant="text" size="large"
                              onClick={() => {
                                setSeconds2(30);
                                setTimerIsActive2(true);
                                setEmailOTPexpirationDate(moment().add(300, 'seconds'));
                                sendEmailOTP();
                              }}>
                              Resend a new OTP Code to email
                            </Button>}
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={3}
                          sx={{ mb: 2 }}
                        >
                          <Typography variant="body1" sx={{ mb: 1, textAlign: 'center', minWidth: 80, maxWidth: '80%' }}>
                            Email OTP Expiry: {emailOTPexpirationDate.format('MMM DD, YYYY hh:mm:ss a')}
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="center"
                          spacing={3}
                          sx={{ mt: 1 }}
                        >
                          <Button variant="contained"
                            onClick={() => {
                              verifyMailOTP()
                            }}>
                            Verify Email OTP
                          </Button>
                        </Stack>
                      </>
                    }

                    <Divider sx={{ mt: 4, mb: 5 }} />

                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={3}
                      sx={{ mt: 5 }}
                    >
                      <Button variant="text" size="large">
                        {PROVIDER_STATE === 'emailpassword' ?
                          <>
                            <Link
                              href="/registration-step2"
                              sx={{ textDecoration: "none" }}
                              color="text.linkActive"
                            >
                              Back{" "}
                            </Link>
                          </>
                          :
                          <>
                            <Link
                              href="/registration"
                              sx={{ textDecoration: "none" }}
                              color="text.linkActive"
                            >
                              Back{" "}
                            </Link>
                          </>}
                      </Button>
                      <Button type="submit" variant="contained" size="large">
                        Register
                      </Button>
                    </Stack>
                  </>}

              </form>
            </FormControl>

          </Stack>
        </Paper>
      </Container >
    </Box >
  );
}
