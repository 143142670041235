import React, {
  useState,
  useEffect,
} from 'react';
import {
  Box, Typography, Paper, Table,
  TableBody, TableCell, TableContainer,
  TableHead, TableRow, Button, Grid,
  Stack, TextField, Modal, Backdrop,
  Fade, Divider, FormControl
} from "@mui/material";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import moment from 'moment-timezone';
import MaterialUITable from "../components/MaterialUITable";
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";

import { useFormik, useField, useFormikContext } from "formik";
import APILoadingOverlay from "../components/APILoadingOverlay";
import APIErrorModal from "../components/APIErrorModal";
import * as Yup from 'yup';
import axios from 'axios';
//printing
import coco_logo from "../assets/cocolife-horizontal.png";
import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { renderToString } from "react-dom/server";
// const premiumPaymentList = [
//   PremiumPayments("200626", "200626", "99944798", "06-26-2020", "4,592.04", "Premium Payment"),
//   PremiumPayments("232626", "203226", "11244223", "03-26-2020", "4,592.04", "Premium Payment"),
//   PremiumPayments("238926", "788826", "87844877", "03-26-2020", "4,592.04", "Premium Payment"),
//   PremiumPayments("232355", "738990", "87555447", "02-26-2020", "4,592.04", "Premium Payment"),
// ]

export default function Ledger(props) {
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [premiumPayments, setPremiumPayments] = useState({});
  const [dividends, setDividends] = useState({});
  const [PDFFBR, setPDFFBR] = useState({});
  const [basicInfo, setBasicInfo] = useState({});
  const [pageNumber, setPageNumber] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [printStartDate, setPrintStartDate] = useState({});
  const [printEndDate, setPrintEndDate] = useState({});
  const [lowestDate, setLowestDate] = useState({});
  const [highestDate, setHighestDate] = useState({});
  const DEBUG_MODE = false

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);
  const [modalSetPrintPaymentDate, setModalSetPrintPaymentDate] = useState(false);
  const [modalSetPremiumDateError, setModalSetPremiumDateError] = useState(false);

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [isLoading, setIsLoading] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 350,
    maxWidth: 600,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    async function init() {

      let premiumPaymentsReqData = {
        // policyNumber: 'T0095469',
        policyNumber: POLICY_DATA,
      }

      await axios.post(process.env.REACT_APP_API_URL + '/api/getPremiumPayments', premiumPaymentsReqData)
        .then(async (res) => {
          console.log("getPremiumPayments res.data.Data", res.data.Data);

          if (res === 'null') {
            setapiName('getPremiumPayments')
            setapiResponse({
              Code: '408',
              Message: "API request timeout."
            })
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPremiumPayments res is null')
            return
          }

          if (res.data.Success) {
            if (res.data.Data.PremiumPayments.length > 0) {
              let lowestDateTMP = moment(res.data.Data.PremiumPayments[0].ORDate)
              let highestDateTMP = moment(res.data.Data.PremiumPayments[0].ORDate)

              res.data.Data.PremiumPayments.map((item, index) => {
                // console.log("item.ORDate", moment(item.ORDate, "MM/DD/YYYY hh:mm:ss a"))
                if (moment(item.ORDate).isValid()) {
                  if (highestDateTMP.isBefore(moment(item.ORDate, "MM/DD/YYYY hh:mm:ss a"))) {
                    highestDateTMP = moment(item.ORDate, "MM/DD/YYYY hh:mm:ss a")
                  }
                  if (lowestDateTMP.isAfter(moment(item.ORDate, "MM/DD/YYYY hh:mm:ss a"))) {
                    lowestDateTMP = moment(item.ORDate, "MM/DD/YYYY hh:mm:ss a")
                  }
                  item.ORDate = moment(item.ORDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                }
                item.AmountPaid = item.AmountPaid.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              })

              setPremiumPayments(res.data.Data.PremiumPayments);

              console.log("lowestDateTMP", lowestDateTMP)
              console.log("highestDateTMP", highestDateTMP)
              setLowestDate(lowestDateTMP)
              setHighestDate(highestDateTMP)
              setPrintStartDate(lowestDateTMP)
              setPrintEndDate(highestDateTMP)

              await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
                policyNumber: POLICY_DATA,
              }).then(async (res) => {
                console.log("getPolicyProfile res.data.Data", res.data.Data);

                if (res === 'null') {
                  setapiName('getPolicyProfile')
                  setapiResponse({
                    Code: '408',
                    Message: "API request timeout."
                  })
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'getPolicyProfile res is null')
                  return
                }

                if (res.data.Success) {
                  let policProfileTMP = res.data.Data
                  console.log("policProfileTMP", policProfileTMP)

                  policProfileTMP.PlanName = formatAPIData(policProfileTMP.PlanName, 'empty')
                  policProfileTMP.Status = formatAPIData(policProfileTMP.Status, 'empty')
                  policProfileTMP.PayMode = formatAPIData(policProfileTMP.PayMode, 'empty')
                  policProfileTMP.PolicyCurrency = formatAPIData(policProfileTMP.PolicyCurrency, 'empty')
                  policProfileTMP.FaceAmount = formatAPIData(policProfileTMP.FaceAmount, 'decimal')
                  policProfileTMP.TotalPremium = formatAPIData(policProfileTMP.TotalPremium, 'decimal')
                  policProfileTMP.BillingPremium = formatAPIData(policProfileTMP.BillingPremium, 'decimal')
                  policProfileTMP.NonforfeitureOption = formatAPIData(policProfileTMP.NonforfeitureOption, 'empty')
                  policProfileTMP.DividendOption = formatAPIData(policProfileTMP.DividendOption, 'empty')
                  policProfileTMP.IssueDate = formatAPIData(policProfileTMP.IssueDate, 'date')
                  policProfileTMP.NextDueDate = formatAPIData(policProfileTMP.NextDueDate, 'date')


                  await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyOwnerProfile', {
                    policyNumber: POLICY_DATA,
                  }).then(async (res) => {
                    console.log("getPolicyOwnerProfile res.data.Data", res.data.Data);

                    if (res === 'null') {
                      setapiName('getPolicyOwnerProfile')
                      setapiResponse({
                        Code: '408',
                        Message: "API request timeout."
                      })
                      setAPIErrorModalOpen(true)
                      console.log('issue', 'getPolicyOwnerProfile res is null')
                      return
                    }

                    if (res.data.Success) {
                      let policyOwnerProfileTMP = res.data.Data
                      console.log("policyOwnerProfileTMP", policyOwnerProfileTMP)

                      policyOwnerProfileTMP.Name = formatAPIData(policyOwnerProfileTMP.Name, 'empty')
                      policyOwnerProfileTMP.Birthday = formatAPIData(policyOwnerProfileTMP.Birthday, 'date')
                      policyOwnerProfileTMP.Company = formatAPIData(policyOwnerProfileTMP.Company, 'empty')
                      policyOwnerProfileTMP.MailingAddress = formatAPIData(policyOwnerProfileTMP.MailingAddress, 'empty')
                      policyOwnerProfileTMP.PermanentAddress = formatAPIData(policyOwnerProfileTMP.PermanentAddress, 'empty')
                      policyOwnerProfileTMP.EmailAddress = formatAPIData(policyOwnerProfileTMP.EmailAddress, 'empty')
                      policyOwnerProfileTMP.Viber = formatAPIData(policyOwnerProfileTMP.Viber, 'empty')
                      policyOwnerProfileTMP.MobileNumber = formatAPIData(policyOwnerProfileTMP.MobileNumber, 'empty')
                      policyOwnerProfileTMP.Phone = formatAPIData(policyOwnerProfileTMP.Phone, 'empty')

                      let basicInfoTMP = { ...policProfileTMP, ...policyOwnerProfileTMP };
                      console.log("basicInfoTMP", basicInfoTMP)

                      setBasicInfo(basicInfoTMP);

                      setIsLoading(false)


                    } else {
                      setapiName('getPolicyOwnerProfile')
                      setapiResponse(res.data)
                      setAPIErrorModalOpen(true)
                      console.log('issue', 'getPolicyOwnerProfile res', res.data.Response)
                      return
                    }
                  })

                } else {
                  setapiName('getPolicyProfile')
                  setapiResponse(res.data)
                  setAPIErrorModalOpen(true)
                  console.log('issue', 'getPolicyProfile res', res.data.Response)
                  return
                }
              })
            }

          } else {
            setapiName('getPremiumPayments')
            setapiResponse(res.data)
            setAPIErrorModalOpen(true)
            console.log('issue', 'getPremiumPayments res', res.data.Response)
            return
          }

          setIsLoading(false)
        })


      await axios.post(process.env.REACT_APP_API_URL + '/api/getDividends', {
        policyNumber: POLICY_DATA,
      }).then(async (res) => {
        console.log("getDividends res.data.Data", res.data.Data);

        if (res === 'null') {
          setapiName('getDividends')
          setapiResponse({
            Code: '408',
            Message: "API request timeout."
          })
          setAPIErrorModalOpen(true)
          console.log('issue', 'getDividends res is null')
          return
        }

        if (res.data.Success) {
          res.data.Data.Dividends.map((item, index) => {
            if (moment(item.TransactionDate).isValid()) {
              item.TransactionDate = moment(item.TransactionDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
            }
            item.AccumulatedAmount = item.AccumulatedAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            item.Interest = item.Interest.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            item.TransactionAmount = item.TransactionAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            item.InterestRate = item.InterestRate.toString() + '%'
          })
          setDividends(res.data.Data.Dividends);


          await axios.post(process.env.REACT_APP_API_URL + '/api/getPDFFBR', {
            policyNumber: POLICY_DATA,
          }).then(async (res) => {
            console.log("getPDFFBR res.data.Data", res.data.Data);

            if (res === 'null') {
              setapiName('getPDFFBR')
              setapiResponse({
                Code: '408',
                Message: "API request timeout."
              })
              setAPIErrorModalOpen(true)
              console.log('issue', 'getPDFFBR res is null')
              return
            }

            if (res.data.Success) {
              res.data.Data.PDFFBR.map((item, index) => {
                if (moment(item.ProcessedDate).isValid()) {
                  item.ProcessedDate = moment(item.ProcessedDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                }
                if (moment(item.TransactionDate).isValid()) {
                  item.TransactionDate = moment(item.TransactionDate, "MM/DD/YYYY hh:mm:ss a").format('MM-DD-YYYY')
                }
                item.Interest = item.Interest.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                item.AccumulatedAmount = item.AccumulatedAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                item.TransactionAmount = item.TransactionAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                item.InterestRate = item.InterestRate.toString() + '%'
              })

              setPDFFBR(res.data.Data.PDFFBR);

            } else {
              setapiName('getPDFFBR')
              setapiResponse(res.data)
              setAPIErrorModalOpen(true)
              console.log('issue', 'getPDFFBR res', res.data.Response)
              return
            }
          })

        } else {
          setapiName('getDividends')
          setapiResponse(res.data)
          setAPIErrorModalOpen(true)
          console.log('issue', 'getDividends res', res.data.Response)
          return
        }
      })

    }
    init()
  }, [])

  const RegiSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .min(8, 'At least 8 characters')
      .max(50, 'Too Long!')
      .required('Required'),
    newPassword: Yup.string()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .min(8, 'At least 8 characters')
      .max(50, 'Too Long!')
      .required('Required'),
    confirmPassword: Yup.string()
      .matches(/^\S*$/, 'Whitespace is not allowed')
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Required'),
  });

  // const formikSetPrintDates = useFormik({
  //   initialValues: {
  //     oldPassword: '',
  //     newPassword: '',
  //     confirmPassword: ''
  //   },
  //   enableReinitialize: true,
  //   validationSchema: RegiSchema,
  //   onSubmit: async values => {
  //     // console.log("formikSetPrintDates.values", formikSetPrintDates.values)
  //     // setFormValues({ ...values })
  //     // console.log("username", username)
  //     // console.log("oldPassword", formikSetPrintDates.values.oldPassword)


  //     const string = renderToString(<COCO_PREMIUM_DETAILS />);
  //     pdf.html(string).then(() => {
  //       pdf.autoTable({
  //         head: [{
  //           ORNumber: "OR Number",
  //           ORDate: "OR Date",
  //           AmountPaid: "Amount Paid",
  //           Remarks: "Remarks"
  //         }],
  //         body: filteredPremiumPayments,
  //         startY: 200,
  //         showHead: 'firstPage',
  //       })
  //     }).then(() => {
  //       pdf.save(`Payments Report - ${POLICY_DATA}`)
  //       setModalSetPrintPaymentDate(false)
  //     })
  //   }
  // })

  function formatAPIData(value, type) {
    switch (type) {
      case 'empty':
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return value
        }
      case 'decimal':
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        }
      case 'date':
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return moment(value, "MM/DD/YYYY hh:mm:ss a").format('MMMM D, YYYY')
        }

      default:
        if (value == '' || value == null || value == '-') {
          return '--'
        } else {
          return value
        }
    }
  }

  //PRINTING 
  const HEIGHT = 842.4 * 0.749
  const WIDTH = 597.6 * 0.749
  const pdf = new jsPDF('p', 'pt', [597.6, 842.4]);

  const COCO_HEADER = () => (
    <div>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", maxHeight: "10vh", color: 'black' }}>
        <div style={{ display: "flex", flex: 1, justifyContent: "flex-start" }}>
          <img alt={"coco_logo"} src={coco_logo} style={{ width: "100%", alignSelf: "center" }} />
        </div>
        <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyContent: "flex-end", paddingLeft: "25%" }}>
          <div style={{ display: "flex", flex: 1, maxHeight: "5vh", backgroundColor: "rgba(24,99,168,1)", alignItems: "center" }}>
            <div style={{ fontSize: "1.1em", fontWeight: "bold", color: "white" }}>
              POLICYHOLDER
            </div>
          </div>
          <div style={{ display: "flex", flex: 1, maxHeight: "2vh", backgroundColor: "rgba(32,176,75,1)", alignItems: "center" }}>
            <div style={{ fontSize: "0.8em", fontWeight: "bold", marginLeft: "", color: "white" }}>
              PAYMENTS REPORT
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const POLICY_STYLE = { fontSize: "0.6em", letterSpacing: "0.1em", textAlign: "left", marginTop: "1vh" }
  const HEAD_BIG_TEXT_CONTAINER = { display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(24,99,168,1)" }
  const HEADER_BIG_TEXT = { fontSize: "0.9em", letterSpacing: "0.1em", color: "white", fontWeight: "bold" }

  const COCO_POLICY_INFO = () => (
    <div style={{ display: "flex", flex: 1, flexDirection: "row", marginTop: "3vh", color: 'black' }}>
      <div style={{ display: "flex", flex: 7, flexDirection: "column" }}>
        <div style={{ display: "flex", flex: 1, flexDirection: "row", paddingLeft: `${WIDTH * 0.025}pt` }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={POLICY_STYLE}>
              {
                `Policy Holder: ${basicInfo.Name}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Policy Number: ${POLICY_DATA}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Company: ${basicInfo.Company}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Plan: ${basicInfo.PlanName}`
              }
            </div>
            <div style={{ ...POLICY_STYLE, letterSpacing: "0.05em" }}>
              {
                `Face Amount: ${basicInfo.FaceAmount}`
              }
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column", paddingLeft: "10%" }}>
            <div style={POLICY_STYLE}>
              {
                `Issue Date: ${basicInfo.PayMode}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Pay Mode: ${basicInfo.NonforfeitureOption}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Modal Premium: ${basicInfo.DividendOption}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Next Due Date: ${basicInfo.NextDueDate}`
              }
            </div>
            <div style={POLICY_STYLE}>
              {
                `Status: ${basicInfo.Status}`
              }
            </div>
          </div>
        </div>


      </div>
    </div>
  )
  const COCO_PREMIUM_DETAILS = () => (
    <div style={{ display: "flex", flex: 1, backgroundColor: "rgba(207,236,240,0.0)", width: `${WIDTH}pt`, height: `${HEIGHT}pt` }}>
      <div style={{ paddingTop: `${HEIGHT * 0.0125}pt`, paddingBottom: `${HEIGHT * 0.0125}pt`, paddingRight: `${WIDTH * 0.025}pt`, paddingLeft: `${WIDTH * 0.025}pt` }}>
        {COCO_HEADER()}
        {COCO_POLICY_INFO()}
        {/* <div style={{ backgroundColor: "rgba(207,236,240,0.9)", fontSize: "0.5em", letterSpacing: "0.1em", textAlign: "left", marginTop: "7vh" }}>
          {`${new Date()}`}
        </div> */}
      </div>
    </div>
  )

  const PRINT_PAYMENTS_REPORT = () => {
    // const string = renderToString(<COCO_BILLING_DETAILS />);
    // pdf.html(string).then(() => {
    //   pdf.autoPrint({ variant: 'non-conform' });
    //   pdf.save(`Billing Notice - ${POLICY_DATA}`)
    //   window.open(pdf.output('bloburl'), '_blank')
    // })'

    let filteredPremiumPayments = []

    console.log("printStartDate", moment(printStartDate).format('MM/DD/YYYY'))
    console.log("printEndDate", moment(printEndDate).format('MM/DD/YYYY'))

    premiumPayments.map((row, index) => {
      let rowDate = moment(row.ORDate)
      let printStartDateTMP = moment(printStartDate)
      let printEndDateTMP = moment(printEndDate)

      // console.log("row.ORDate test", row.ORDate)
      // console.log("row.ORDate isSameOrAfter", rowDate.isSameOrAfter(printStartDateTMP, 'days'))
      // console.log("row.ORDate isSameOrBefore", rowDate.isSameOrBefore(printEndDateTMP, 'days'))
      if (rowDate.isSameOrAfter(printStartDateTMP, 'days') && rowDate.isSameOrBefore(printEndDateTMP, 'days')) {
        if (moment(row.ORDate).isValid()) {
          row.ORDate = moment(row.ORDate, "MM/DD/YYYY hh:mm:ss a").format('MM/DD/YYYY')
        }
        console.log("row.ORDate added", row.ORDate)

        if (row.ORNumber == '' || row.ORNumber == null || row.ORNumber == '-' || row.ORNumber == 0) {
          row.ORNumber = '--'
        }


        if (filteredPremiumPayments.length > 0) {
          filteredPremiumPayments = [...filteredPremiumPayments, row]
        } else {
          filteredPremiumPayments = [row]
        }
      }
    })

    //Added internal functions 
    function computeTotalPayments() {
      let total = 0
      //TODO fix with correct data
      if (filteredPremiumPayments && filteredPremiumPayments.length > 0) {
        filteredPremiumPayments.map((payment) => total += parseFloat(payment.AmountPaid.toString().replace(',', '')))
      }
      console.log("total", total)
      return total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      // return total.toFixed(2)
    }

    const string = renderToString(<COCO_PREMIUM_DETAILS />);
    pdf.html(string).then(() => {
      pdf.autoTable({
        head: [{
          ORNumber: "OR Number",
          ORDate: "OR Date",
          AmountPaid: "Amount Paid",
          Remarks: "Remarks"
        }],
        body: filteredPremiumPayments,
        startY: 250,
        showHead: 'firstPage',
      })

      //added for pooters
      const pageCount = pdf.internal.getNumberOfPages();
      pdf.setFont('helvetica', 'italic')
      pdf.setFontSize(8)
      for (var i = 1; i <= pageCount; i++) {
        pdf.setPage(i)
        pdf.text(`Date/Time: ${new Date()}`, 37, pdf.internal.pageSize.height - 18);
        pdf.text(`Page: ${String(i)}`, pdf.internal.pageSize.width - 74, pdf.internal.pageSize.height - 18);

      }

      pdf.setFont('times', 'normal')
      pdf.setFontSize(12)
      let posY = pdf.lastAutoTable.finalY;
      pdf.text(`Total Records: ${filteredPremiumPayments.length}`, 40, posY + 20);
      pdf.text(`Total Payments: ${computeTotalPayments()}`, pdf.internal.pageSize.width / 2, posY + 20);
      //END OF for footers
    })
      .then(() => {
        pdf.autoPrint({ variant: 'non-conform' });
        pdf.save(`Payments Report - ${POLICY_DATA}`)
        window.open(pdf.output('bloburl'), '_blank')
        setModalSetPrintPaymentDate(false)
      })
  };

  const handleSubmitDateRange = () => {

    if (moment(printStartDate).isSameOrAfter(moment(lowestDate)) &&
      moment(printStartDate).isSameOrBefore(moment(printEndDate)) &&
      moment(printEndDate).isSameOrBefore(moment(highestDate)) &&
      moment(printEndDate).isSameOrAfter(moment(printStartDate))) {
      setModalSetPrintPaymentDate(false)
      PRINT_PAYMENTS_REPORT()
    } else {
      setModalSetPremiumDateError(true)
    }
  }

  return (
    <Box>
      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSetPrintPaymentDate}
        onClose={() => {
          setModalSetPrintPaymentDate(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSetPrintPaymentDate}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Print Payments Report
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <FormControl sx={{ my: 1 }} fullWidth>
              {/* <form onSubmit={formikSetPrintDates.handleSubmit} name='formikSetPrintDates' key='formikSetPrintDates'> */}

              <Stack sx={{ alignItems: "center" }}>
                <Typography variant="body1" color="text.secondary"
                  sx={{ mb: 2, textAlign: 'center', minWidth: 80, maxWidth: '100%' }}>
                  Select Start Date
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    // label="Date desktop"
                    inputFormat="MM/dd/yyyy"
                    value={moment(printStartDate).format('MM/DD/YYYY')}
                    onChange={(value) => {
                      setPrintStartDate(moment(value))
                    }}
                    maxDate={new Date(printEndDate)}
                    minDate={new Date(lowestDate)}
                    renderInput={(params) => <TextField size="small"{...params} />}
                  />
                </LocalizationProvider>
                {/* <TextField
                    name="newPassword"
                    variant="outlined"
                    hiddenLabel
                    value={formikSetPrintDates.values.newPassword}
                    placeholder='New Password'
                    onChange={formikSetPrintDates.handleChange}
                    type="password"
                    sx={{ minWidth: '80%', maxWidth: '100%' }}
                  />
                  {formikSetPrintDates.errors.newPassword ? <Typography color="red">{formikSetPrintDates.errors.newPassword}</Typography> : null} */}
              </Stack>

              <Stack sx={{ alignItems: "center" }}>
                <Typography variant="body1" color="text.secondary"
                  sx={{ mb: 2, mt: 4, textAlign: 'center', minWidth: 80, maxWidth: '100%' }}>
                  Select End Date
                </Typography>

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    // label="Date desktop"
                    inputFormat="MM/dd/yyyy"
                    value={moment(printEndDate).format('MM/DD/YYYY')}
                    onChange={(value) => {
                      setPrintEndDate(moment(value))
                    }}
                    maxDate={new Date(moment(highestDate))}
                    minDate={new Date(moment(printStartDate))}
                    renderInput={(params) => <TextField size="small"{...params} />}
                  />
                </LocalizationProvider>
                {/* <TextField
                    name="confirmPassword"
                    variant="outlined"
                    hiddenLabel
                    value={formikSetPrintDates.values.confirmPassword}
                    placeholder='Confirm your New Password'
                    onChange={formikSetPrintDates.handleChange}
                    type="password"
                    sx={{ minWidth: '80%', maxWidth: '100%' }}
                  />
                  {formikSetPrintDates.errors.confirmPassword ? <Typography color="red">{formikSetPrintDates.errors.confirmPassword}</Typography> : null} */}
              </Stack>

              <Stack
                direction="row"
                justifyContent="center"
                spacing={3}
                sx={{ mt: 5, width: '100%' }}
              >
                <Button type="submit" variant="contained" size="large"
                  onClick={() => {
                    handleSubmitDateRange()
                  }}>
                  Print
                </Button>
              </Stack>

              {/* </form> */}
            </FormControl>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalSetPremiumDateError}
        onClose={() => {
          setModalSetPremiumDateError(false)
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalSetPremiumDateError}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Date set is out of bounds
            </Typography>

            <Divider sx={{ my: 3, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Start Date must be on or after
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 0 }}
              color="text.secondary"
            >
              {moment(lowestDate).format('MMMM DD, YYYY')}
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              End Date must be on or before
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 0 }}
              color="text.secondary"
            >
              {moment(highestDate).format('MMMM DD, YYYY')}
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setModalSetPremiumDateError(false)
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Typography variant="h4" color="text.primary" sx={{ mb: 5 }}>
        Ledger{" "}
      </Typography>

      <APILoadingOverlay customlabel={loadingCustomLabel}
        open={isLoading} setOnParent={(value) => setIsLoading(value)}>

        {/* <Typography variant="h6">Premium Payments</Typography>
      <Typography variant="body1"  color="text.secondary">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
        commodo consequat.
      </Typography> */}

        {/* //Second Table */}
        <Box sx={{ mt: 7 }}>

          {/* <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Premium Payments
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <Button
                sx={{ mt: 3 }}
                variant="contained"
                onClick={() => {
                  PRINT_BASIC_INFO();
                }} autoFocus >
                Print PDF
              </Button>
            </Grid>
          </Grid> */}

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Typography variant="h6" sx={{ mb: 3 }}>
                Premium Payments
              </Typography>
            </Grid>
            {premiumPayments.length > 0 ?
              //  {billingNotice.TotalAmountDue != 0 ? 
              < Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Button
                  sx={{ mb: 3 }}
                  variant="contained"
                  onClick={() => {
                    setModalSetPrintPaymentDate(true);
                  }} autoFocus >
                  Print Premium Payments
                </Button>
              </Grid>
              : null}
          </Grid>

          {premiumPayments.length > 0 ?
            <MaterialUITable
              data={premiumPayments}
              isLoading={false}
              totalCount={premiumPayments.length}
              currentPage={pageNumber}
              rowsPerPage={rowsPerPage}
              columns={[
                {
                  title: "OR Date",
                  field: "ORDate",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "OR Number",
                  field: "ORNumber",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid'
                  },
                },
                {
                  title: "Amount Paid",
                  field: "AmountPaid",
                  headerStyle: {
                    borderRight: '1px solid'
                  },
                  cellStyle: {
                    borderRight: '1px solid',
                    textAlign: 'right'
                  }
                },
                {
                  title: "Remarks",
                  field: "Remarks",
                },
              ]}
              changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
              changePage={(page, pNumber) => setPageNumber(page)}
            />
            :
            <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
              No Data Available
            </Typography>}

          {
            !IS_VL && <>
              <Typography variant="h6" sx={{ mb: 3, mt: 5 }}>
                Dividends
              </Typography>

              {dividends.length > 0 ?
                <MaterialUITable
                  data={dividends}
                  isLoading={false}
                  totalCount={dividends.length}
                  currentPage={pageNumber}
                  rowsPerPage={rowsPerPage}
                  columns={[
                    {
                      title: "Transaction Date",
                      field: "TransactionDate",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid'
                      },
                    },
                    {
                      title: "Transaction Amt",
                      field: "TransactionAmount",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                        textAlign: 'right'
                      }
                    },
                    {
                      title: "Interest",
                      field: "Interest",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                        textAlign: 'right'
                      }
                    },
                    {
                      title: "Transaction Type",
                      field: "TransactionType",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid'
                      },
                    },
                    {
                      title: "Interest Rate",
                      field: "InterestRate",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                        textAlign: 'right'
                      }
                    },
                    {
                      title: "Accumulated Amt",
                      field: "AccumulatedAmount",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                        textAlign: 'right'
                      }
                    },
                    {
                      title: "Transaction Number",
                      field: "TransactionNumber",
                    },
                  ]}
                  changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                  changePage={(page, pNumber) => setPageNumber(page)}
                />
                :
                <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
                  No Data Available
                </Typography>}
              <Typography variant="h6" sx={{ mb: 3, mt: 5 }}>
                Premium Deposit Fund / Fund Build Rider
              </Typography>

              {PDFFBR.length > 0 ?
                <MaterialUITable
                  data={PDFFBR}
                  isLoading={false}
                  totalCount={PDFFBR.length}
                  currentPage={pageNumber}
                  rowsPerPage={rowsPerPage}
                  columns={[
                    {
                      title: "Transaction Date",
                      field: "TransactionDate",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                      }
                    },
                    {
                      title: "Processed Date",
                      field: "ProcessedDate",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                      }
                    },
                    {
                      title: "Transaction Amt",
                      field: "TransactionAmount",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                        textAlign: 'right'
                      }
                    },
                    {
                      title: "Interest",
                      field: "Interest",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                        textAlign: 'right'
                      }
                    },
                    {
                      title: "Transaction Type",
                      field: "TransactionType",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                      }
                    },
                    {
                      title: "Interest Rate",
                      field: "InterestRate",
                      headerStyle: {
                        borderRight: '1px solid'
                      },
                      cellStyle: {
                        borderRight: '1px solid',
                        textAlign: 'right'
                      }
                    },
                    {
                      title: "Accumulated Amt",
                      field: "AccumulatedAmount",
                      cellStyle: {
                        textAlign: 'right'
                      }
                    },
                  ]}
                  changeRowsPerPage={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
                  changePage={(page, pNumber) => setPageNumber(page)}
                />
                :
                <Typography variant="body1" sx={{ mb: 3, mt: 2 }}>
                  No Data Available
                </Typography>}
            </>
          }
        </Box>
      </APILoadingOverlay>
    </Box>
  );
}
