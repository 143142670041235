import { Button, makeStyles, Stack } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Backdrop from "@mui/material/Backdrop";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CircularProgressWithLabel from "../components/CircularProgressWithLabel";
import LinearProgressWithLabel from "../components/LinearProgressWithLabel";
import APIErrorModal from "../components/APIErrorModal";
import APILoadingOverlay from "../components/APILoadingOverlay";
import { useSelector, useDispatch } from "react-redux";
import { updateLoadingState } from "../features/LoadingControl/LoadingControlSlice";
import { updatePolicyNumber } from "../features/Theming/PolicyNumberSlice";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import cryptoJS from "crypto-js";
import axios from 'axios';
import moment from 'moment-timezone';
import { ref } from "yup";
import { WindowSharp } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 350,
  maxWidth: 600,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default function PayOnline() {
  const navigate = useNavigate();
  let dispatch = useDispatch();

  const LOADING_STATE = useSelector((state) => state.loadingState.value);
  const POLICY_DATA = useSelector((state) => state.policyNumber.value);
  const IS_VL = useSelector((state) => state.policyNumber.isVL)

  const [searchParams, setSearchParams] = useSearchParams();

  const [loadingCustomLabel, setLoadingCustomLabel] = useState("Loading");
  const [selectedPolicyPlanIndex, setSelectedPolicyPlanIndex] = useState(0);
  const [policyList, setPolicyList] = useState([])
  const [paymentTypes, setPaymentTypes] = useState([]);
  // const [selectedPolicyPaymentInfo, setselectedPolicyPaymentInfo] = useState([]);
  const [policyPaymentInfoList, setPolicyPaymentInfoList] = useState([]);

  const [premiumAmt, setPremiumAmt] = useState(0);
  const [pdfAmt, setPDFAmt] = useState(0);
  const [payPDF, setPayPDF] = useState(false);
  const [fbrAmt, setFBRAmt] = useState(0);
  const [payFBR, setPayFBR] = useState(false);
  const [loanInterestAmt, setLoanInterestAmt] = useState(0);
  const [payLoanInterest, setPayLoanInterest] = useState(false);
  const [totalAmountDue, setTotalAmountDue] = useState(0);
  const [isEligible, setIsEligible] = useState(false);
  const [txnid, settxnid] = useState(null);
  const [refno, setrefno] = useState(null);
  const [status, setstatus] = useState(null);
  const [message, setmessage] = useState(null);
  const [digest, setdigest] = useState(null);

  const [justPaid, setJustPaid] = useState(false);

  const [apiName, setapiName] = useState(null);
  const [apiResponse, setapiResponse] = useState({});
  const [APIErrorModalOpen, setAPIErrorModalOpen] = useState(false);
  const [modalDragonpayPayloadInsecure, setModalDragonpayPayloadInsecure] = useState(false);

  const [successTransactionModal, setSuccessTransactionModal] = useState(false);
  const [pendingTransactionModal, setPendingTransactionModal] = useState(false);
  const [failTransactionModal, setFailTransactionModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log("LOADING_STATE pre", LOADING_STATE)
    console.log("isLoading", isLoading)
    dispatch(updateLoadingState(isLoading));
  }, [isLoading])

  useEffect(() => {
    let tmpTotalAmountDue = policyList[selectedPolicyPlanIndex]?.premiumAmount;
    console.log("tmpTotalAmountDue", tmpTotalAmountDue);

    if (payPDF) {
      tmpTotalAmountDue += policyList[selectedPolicyPlanIndex]?.pdf;
      console.log("tmpTotalAmountDue + payPDF", tmpTotalAmountDue);
    }
    if (payFBR) {
      tmpTotalAmountDue += policyList[selectedPolicyPlanIndex]?.fbr;
      console.log("tmpTotalAmountDue + payFBR", tmpTotalAmountDue);
    }
    if (payLoanInterest) {
      tmpTotalAmountDue += policyList[selectedPolicyPlanIndex]?.policyLoanInterest;
      console.log("tmpTotalAmountDue + payLoanInterest", tmpTotalAmountDue);
    }

    setTotalAmountDue(tmpTotalAmountDue)
  }, [selectedPolicyPlanIndex, policyList, payPDF, payFBR, payLoanInterest]);


  useEffect(() => {
    async function init() {

    }
    init()
  }, [])

  useEffect(() => {
    async function init() {
      await axios.post(process.env.REACT_APP_API_URL + '/api/getPaymentTypes')
        .then(async (getPaymentTypesRes) => {
          if (getPaymentTypesRes.data.user === false) {
            //  big error here
            console.log("User not found")
            return
          }
          console.log("getPaymentTypesRes", getPaymentTypesRes)

          setPaymentTypes(getPaymentTypesRes.data.Data.PaymentTypes)

          let paymentPolicyList = [];

          await axios.post(process.env.REACT_APP_API_URL + '/api/getAllPoliciesByCurrentPolicy', {
            policyNumber: POLICY_DATA,
            registered: true,
            forceRegister: true,
          }).then(async (getAllPoliciesByCurrentPolicyRes) => {
            console.log("getAllPoliciesByCurrentPolicyRes", getAllPoliciesByCurrentPolicyRes)

            await Promise.all(
              getAllPoliciesByCurrentPolicyRes.data.user.recordset.map(
                async (policy, index) => {
                  let policyType = 'trad'
                  if (policy.policy_number.split("")[0].toLowerCase() === 'v') {
                    policyType = 'vl'
                  }
                  console.log("policy", policy)

                  // if (policyType != 'vl') {
                  // console.log("getAllPoliciesByCurrentPolicyRes policy", policy)
                  let newPaymentPolicy = {};

                  newPaymentPolicy.policyNumber = policy.policy_number;

                  // console.log("Attempting checkOnlinePayEligibility")
                  // console.log("policyNumber map", policyNumber)

                  await axios.post(process.env.REACT_APP_API_URL + '/api/getPolicyProfile', {
                    // policyNumber: 'T0095469',
                    policyNumber: policy.policy_number,
                  }).then(async (getPolicyProfileRes) => {
                    console.log("getPolicyProfileRes.data.Data", getPolicyProfileRes.data.Data);

                    if (getPolicyProfileRes === 'null') {
                      setapiName('getPolicyProfile')
                      setapiResponse({
                        Code: '408',
                        Message: "API request timeout."
                      })
                      setAPIErrorModalOpen(true)
                      console.log('issue', 'getPolicyProfile getPolicyProfileRes is null')
                      return
                    }

                    if (getPolicyProfileRes.data.Success) {
                      newPaymentPolicy.plan = getPolicyProfileRes.data.Data.PlanName;
                      newPaymentPolicy.status = getPolicyProfileRes.data.Data.Status;

                      await axios.post(process.env.REACT_APP_API_URL + '/api/getOnlinePaymentInfo', {
                        // policyNumber: 'T0095469',
                        policyNumber: policy.policy_number,
                      }).then(async (getOnlinePaymentInfoRes) => {
                        console.log("getOnlinePaymentInfoRes.data.Data", getOnlinePaymentInfoRes.data.Data);

                        if (getOnlinePaymentInfoRes === 'null') {
                          setapiName('getOnlinePaymentInfo')
                          setapiResponse({
                            Code: '408',
                            Message: "API request timeout."
                          })
                          setAPIErrorModalOpen(true)
                          console.log('issue', 'getOnlinePaymentInfoRes is null')
                        }

                        if (getOnlinePaymentInfoRes.data.Success) {
                          newPaymentPolicy.dueDate = getOnlinePaymentInfoRes.data.Data.DueDate;
                          newPaymentPolicy.insuredName = getOnlinePaymentInfoRes.data.Data.InsuredName;
                          newPaymentPolicy.payMode = getOnlinePaymentInfoRes.data.Data.PayMode;
                          newPaymentPolicy.policyCurrency = getOnlinePaymentInfoRes.data.Data.PolicyCurrency;
                          newPaymentPolicy.email = getOnlinePaymentInfoRes.data.Data.EmailAddress;
                          newPaymentPolicy.fbr = getOnlinePaymentInfoRes.data.Data.FBR;
                          newPaymentPolicy.pdf = getOnlinePaymentInfoRes.data.Data.PDF;
                          newPaymentPolicy.policyLoanInterest = getOnlinePaymentInfoRes.data.Data.PolicyLoanInterest;
                          newPaymentPolicy.premiumAmount = getOnlinePaymentInfoRes.data.Data.PremiumAmount;
                          newPaymentPolicy.totalAmountDue = getOnlinePaymentInfoRes.data.Data.TotalAmountDue;

                          if (paymentPolicyList.length > 0) {
                            console.log("added a new policy:", newPaymentPolicy);
                            paymentPolicyList = [...paymentPolicyList, newPaymentPolicy]
                          } else {
                            console.log("first payment policy is", newPaymentPolicy);
                            paymentPolicyList = [newPaymentPolicy]
                          }


                          await axios.post(process.env.REACT_APP_API_URL + '/api/checkOnlinePayEligibility', {
                            policyNumber: policy.policy_number
                          }).then(async (checkOnlinePayEligibilityRes) => {
                            console.log("checkOnlinePayEligibilityRes", checkOnlinePayEligibilityRes)
                            if (checkOnlinePayEligibilityRes?.data?.Data?.Remarks != 'Success') {
                              console.log(policy.policy_number, "failed eligibility")
                              newPaymentPolicy.eligible = false;
                              newPaymentPolicy.reason = checkOnlinePayEligibilityRes?.data?.Data?.Remarks;
                              setJustPaid(false)
                            } else {
                              console.log(policy.policy_number, "passed eligibility")
                              newPaymentPolicy.eligible = true;
                              setIsEligible(true)
                              setJustPaid(false)
                            }
                            // console.log("final paymentPolicyList", paymentPolicyList)
                            // setPolicyList(paymentPolicyList)

                            // console.log("paymentPolicyList[selectedPolicyPlanIndex]?.status", paymentPolicyList[selectedPolicyPlanIndex]?.status)
                            // if (paymentPolicyList[selectedPolicyPlanIndex]?.status === 'INFORCE') {
                            //   setJustPaid(true)
                            // }

                            console.log("checkOnlinePayEligibilityRes setIsLoading")
                          })

                        } else {
                          setapiName('getOnlinePaymentInfo')
                          setapiResponse(getOnlinePaymentInfoRes.data.Response)
                          setAPIErrorModalOpen(true)
                          console.log('issue', 'getOnlinePaymentInfoRes', getOnlinePaymentInfoRes.data.Response)
                        }
                      })

                    } else {
                      setapiName('getPolicyProfile')
                      setapiResponse(getPolicyProfileRes.data.Response)
                      setAPIErrorModalOpen(true)
                      console.log('issue', 'getPolicyProfile getPolicyProfileRes', getPolicyProfileRes.data.Response)
                    }
                  })
                  // }

                  setIsLoading(false)
                }
              )
            )

            console.log("final paymentPolicyList", paymentPolicyList)
            setPolicyList(paymentPolicyList)


            paymentPolicyList.map((policy, index) => {
              if (POLICY_DATA == policy.policyNumber) {
                setSelectedPolicyPlanIndex(index);
              }
            })

            console.log("paymentPolicyList[selectedPolicyPlanIndex]?.status", paymentPolicyList[selectedPolicyPlanIndex]?.status)

            try {
              let txnid = searchParams.get('txnid')
              let refno = searchParams.get('refno')
              let status = searchParams.get('status')
              let message = searchParams.get('message')
              let digest = searchParams.get('digest')
              let param1 = searchParams.get('param1')
              let param2 = searchParams.get('param2')

              settxnid(txnid)
              setrefno(refno)
              setstatus(status)
              setmessage(message)
              setdigest(digest)

              if (status != null && status != '') {
                await axios.post(process.env.REACT_APP_API_URL + '/api/verifyDragonpayDigest', {
                  txnid,
                  refno,
                  status,
                  message,
                  digest,
                  param1,
                  param2,
                }).then(async (res) => {
                  console.log("verifyDragonpayDigest res", res)
                  if (res.data.Success) {
                    console.log("verifyDragonpayDigest res.data.Success")
                    await axios.post(
                      process.env.REACT_APP_API_URL + '/api/postbackDragonpayTestPaymentPost' +
                      '?txnid=' + txnid +
                      '&refno=' + refno +
                      '&status=' + status +
                      '&message=' + message +
                      '&digest=' + digest +
                      '&param1=' + param1 +
                      '&param2=' + param2, {
                      txnid,
                      refno,
                      status,
                      message,
                      digest,
                      param1,
                      param2,
                      mode: 'returnToScreen'
                    }).then(async (res) => {
                      console.log("res", res)
                      if (param1 != null) {
                        // Check if policy exists and if current user owns it
                        // dispatch(updatePolicyNumber(param1));

                        paymentPolicyList.map((policy, index) => {
                          if (param1 == policy.policyNumber) {
                            setSelectedPolicyPlanIndex(index);
                          }
                        })
                      }

                      if (status === 'S') {
                        setJustPaid(true)
                        setSuccessTransactionModal(true)
                      } else if (status === 'P') {
                        setPendingTransactionModal(true)
                      } else if (status === 'F') {
                        setFailTransactionModal(true)
                      }

                      setIsLoading(false)
                    }).catch(() => {
                      setIsLoading(false)
                      setFailTransactionModal(true)
                    })

                  } else {
                    setIsLoading(false)
                    setModalDragonpayPayloadInsecure(true)
                  }
                })
              }

            } catch (error) {

              setIsLoading(false)
              console.log("axios error", error);
            }
          })
        }).catch((getPaymentTypesError) => {

          setIsLoading(false)
          setapiName('getPaymentTypes')
          setapiResponse(getPaymentTypesError?.data?.Response)
          setAPIErrorModalOpen(true)
          console.log('issue', 'getPaymentTypesError', getPaymentTypesError)
        })
    }
    init()
  }, [])

  const handleChange = (event) => {
    if (event.target.value) {
      console.log('selected', event.target.value)
      setSelectedPolicyPlanIndex(event.target.value)
      // console.log("event.target.value", event.target.value)
      // console.log("policyList[event.target.value -1]", policyList[event.target.value])

      if (policyList[event.target.value]?.PolicyStatus === 'INFORCE') {
        setJustPaid(false)
      } else {
        setJustPaid(false)
      }
    }
  };

  const doPayment = async () => {
    if (payment != null && payment != '') {

      let truncatedPayment = payment.slice(0, 3);

      await axios.post(process.env.REACT_APP_API_URL + '/api/getUserByPolicyNumber', {
        policyNumber: POLICY_DATA,
      }).then(async (getUserByPolicyNumberRes) => {
        if (getUserByPolicyNumberRes.data.user === false) {
          //  big error here
          console.log("User not found")
          return
        }
        console.log("getUserByPolicyNumberRes", getUserByPolicyNumberRes)

        let usernameTmp = getUserByPolicyNumberRes.data.user.recordset[0].username
        let truncatedUsername = usernameTmp.slice(0, 10);

        let newDueDate = moment(policyList[selectedPolicyPlanIndex]?.dueDate, 'MM/DD/YYYY').isValid() ? moment(policyList[selectedPolicyPlanIndex]?.dueDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : null;

        let reqJson = {
          policyNumber: policyList[selectedPolicyPlanIndex].policyNumber,
          dueDate: newDueDate,
          amountDue: totalAmountDue,
          currencyType: policyList[selectedPolicyPlanIndex]?.policyCurrency,
          paymentType: truncatedPayment,
          encodedBy: truncatedUsername,
          addURL: '',
        }
        console.log("saveOLPaymentHeader reqJson", reqJson)

        await axios.post(process.env.REACT_APP_API_URL + '/api/saveOLPaymentHeader',
          reqJson
        ).then(async (saveOLPaymentHeader) => {
          console.log('saveOLPaymentHeader saveOLPaymentHeader', saveOLPaymentHeader)

          if (saveOLPaymentHeader.data.Data?.HeaderID == null) {
            console.log('saveOLPaymentHeader saveOLPaymentHeader is null')

            setapiName('saveOLPaymentHeader')
            setapiResponse(saveOLPaymentHeader.data.Response)
            setAPIErrorModalOpen(true)
            console.log('issue', 'saveOLPaymentHeaderRes', saveOLPaymentHeader.data.Response)
          } else {
            console.log("payment", payment);

            await axios.post(process.env.REACT_APP_API_URL + '/api/createDragonpayTestPayment', {
              policyNumber: policyList[selectedPolicyPlanIndex].policyNumber,
              headerID: saveOLPaymentHeader.data.Data.HeaderID,
              paymentMethod: payment,
              dueDate: newDueDate,
              payFBR: payFBR,
              payPDF: payPDF,
              payLoanInterest: payLoanInterest,
            }).then(async (res) => {
              console.log("createDragonpayTestPayment res", res)
              // window.location.replace(res.data)

              if (res.data?.data?.Success === false) {
                console.log('createDragonpayTestPayment error', res.data)
                return
              }

              window.open(res.data, '_blank');

              // console.log("paymentPolicyList[selectedPolicyPlanIndex]?.status", paymentPolicyList[selectedPolicyPlanIndex]?.status)
              // if (paymentPolicyList[selectedPolicyPlanIndex]?.status === 'INFORCE') {
              //   setJustPaid(true)
              // }
            })
          }
        })

      }).catch((error) => {
        setapiName('DB Connection')
        setapiResponse({
          Code: '',
          Message: "Connection Failed."
        })
        setAPIErrorModalOpen(true)
        console.log('issue getUserByPolicyNumber', error)
      })
    } else {
      setModalErrorPaymentMethod(true)
    }
  }

  const [payment, setPayment] = React.useState("");
  const handleChangePayment = (event) => {
    setPayment(event.target.value);
  };

  const [open, setOpen] = React.useState(false);
  const [modalErrorPaymentMethod, setModalErrorPaymentMethod] = useState(false);
  const handleOpen = () => !justPaid ? setOpen(true) : setOpen(false);
  const handleClose = () => setOpen(false);

  const [accepted, setAccepted] = React.useState(false);
  const handlea = () => setAccepted(true);
  const handleac = () => setAccepted(false);

  function acceptTerms() {
    handlea();
    handleClose();
  }

  function cancelTerms() {
    handleac();
    // console.log("cancel lol")
    handleClose();
  }

  return (
    <div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalDragonpayPayloadInsecure}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setModalDragonpayPayloadInsecure(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalDragonpayPayloadInsecure}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Dragonpay Payment Error
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '100%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              There was an issue verifying the Dragonpay Payment.
            </Typography>

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Please contact Dragonpay with the provided reference number.
            </Typography>

            {refno != null && refno != '' ?

              <Typography
                id="transition-modal-description"
                align="center"
                sx={{ mt: 2 }}
                color="text.secondary"
              >
                Reference number: {refno}
              </Typography>
              : null}

            <Button variant="contained" onClick={() => {
              setModalDragonpayPayloadInsecure(false)
            }} sx={{ mt: 4 }}>
              Ok
            </Button>
          </Box>
        </Fade>
      </Modal>





      <APIErrorModal apiName={apiName} response={apiResponse} open={APIErrorModalOpen} />
      <Dialog
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mt: 2 }}>
          {"Online Credit Card Payment Terms and Conditions"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <ol type="1">
              <li>
                By using the COCOLIFE online payment facility, I am authorizing
                COCOLIFE to charge premium payments due to COCOLIFE from my
                credit card for my enrolled policies.
              </li>
              <li>
                COCOLIFE online payment facility also allows me to use valid
                credit cards of any of my immediate family members (spouse,
                children,parents, brothers, and sisters). In such cases, I have
                expressly authorized by the cardholder or given consent to use
                their credit card for my payment transaction under my enrolled
                policies using this facility COCOLIFE will not be held liable in
                case of dispute which may arise in the course of transaction.
              </li>
              <li>
                The payment transaction through credit crad using COCOLIFE's
                online payment facility in{" "}
                <strong>non-cancellable/non-refundable</strong>. Should there be
                error or disput in the transaction, I understand that I am given
                thirty (30) calendar days from date of transaction to contact
                COCOLIFE Customer Care and submit written notice with valid
                evidence to COCOLIFE Head Office.
              </li>
              <li>
                I hereby understand that any charges or applicable fees
                resulting from my request for reversal which will be approved by
                COCOLIFE shall be for my account. In case I use the card of my
                immediate family, any charge resulting from the cardholder's
                request for a reversal shall be for their account
              </li>
              <li>
                An official receipt from COCOLIFE and Credit Card Statement of
                Account which reflect the total amount charged shall serve as
                proof of payment on my enrolled policies. The official receipt
                will be sent directly to the mailing address of policy holder.
              </li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mt: 2, mb: 2 }}>
          <Button variant="contained" onClick={cancelTerms}>Cancel</Button>
          <Button variant="contained" onClick={acceptTerms} autoFocus>
            I agree to the terms and conditions
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        maxWidth="md"
        open={modalErrorPaymentMethod}
        onClose={() => {
          setModalErrorPaymentMethod(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{ mt: 2 }}>
          {"No Payment Type selected!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select a Payment Type!
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mt: 2, mb: 2 }}>
          <Button variant="contained" onClick={() => {
            setModalErrorPaymentMethod(false)
          }}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={successTransactionModal}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setSuccessTransactionModal(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={successTransactionModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Transaction Successfull!
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '80%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              Your payment for policy {policyList[selectedPolicyPlanIndex]?.policyNumber} was successful!
            </Typography>

            {refno != null && refno != '' ?

              <Typography
                id="transition-modal-description"
                align="center"
                sx={{ mt: 2 }}
                color="text.secondary"
              >
                Reference number: {refno}
              </Typography>
              : null}

            <Button variant="contained"
              href={`${process.env.REACT_APP_DOMAIN_URL}/mypolicy`}
              onClick={() => {
                setSuccessTransactionModal(false)
                // navigate("/mypolicy");
                // window.location.reload(true);
              }}
              sx={{ mt: 4 }}>
              Proceed
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={pendingTransactionModal}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setPendingTransactionModal(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={pendingTransactionModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Pending Transaction
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '80%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              {message != null ?
                message.split(']')[1]
                :
                'Your transaction is pending'}
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setPendingTransactionModal(false)
                navigate("/mypolicy");
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={failTransactionModal}
        onClose={() => {
          (event, reason) => {
            if (reason && reason == "backdropClick")
              return;
            setFailTransactionModal(false)
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={failTransactionModal}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" color="text.primary">
              Transaction Failed
            </Typography>

            <Divider sx={{ mt: 2, mb: 1, width: '80%' }} />

            <Typography
              id="transition-modal-description"
              align="center"
              sx={{ mt: 2 }}
              color="text.secondary"
            >
              There was an issue with your transaction.
            </Typography>

            <Button variant="contained"
              onClick={() => {
                setFailTransactionModal(false)
                navigate("/mypolicy");
              }}
              sx={{ mt: 4 }}>
              Ok
            </Button>

          </Box>
        </Fade>
      </Modal>


      <Typography variant="h4" color="text.primary" sx={{ mb: 5 }}>
        Pay Online
      </Typography>

      <APILoadingOverlay customlabel={loadingCustomLabel} open={isLoading} setOnParent={(value) => setIsLoading(value)}>

        {process.env.REACT_APP_ENV === 'local' || (process.env.REACT_APP_ENV === 'test-server' && POLICY_DATA === 'B0320025') ?
          // {process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'test-server' ?
          <>
            {isEligible ?
              <Button
                sx={{ mb: 3 }}
                variant="contained"
                onClick={() => {
                  setIsEligible(false);
                  setJustPaid(true)
                }} autoFocus >
                Disable Test Payment
              </Button>
              :
              <Button
                sx={{ mb: 3 }}
                variant="contained"
                onClick={() => {
                  setIsEligible(true);
                  setJustPaid(false)
                }} autoFocus >
                Enable Test Payment
              </Button>}
          </>
          : null}

        <Grid container spacing={3}>
          <Grid item xs={12} lg={8}>

            {/* <Paper variant="outlined" sx={{ p: 3 }}>
              <Stack>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  Select a Policy
                </Typography>
                <Typography variant="overline">
                  Choose a Policy from the Dropdown Menu
                </Typography>

                <Select
                  size="small"
                  displayEmpty
                  inputProps={{ "aria-label": "Without label", id: 'uncontrolled-native' }}
                  //   defaultValue="Select a Policy
                  value={selectedPolicyPlanIndex}
                  onChange={handleChange} >

                  {policyList.map((policy, index) => {
                    let policyType = 'trad'
                    if (policy.policyNumber.split("")[0].toLowerCase() === 'v') {
                      policyType = 'vl'
                    }

                    if (policyType != 'vl') {
                      return (
                        <MenuItem
                          key={index}
                          value={index}>
                          {`${policy.policyNumber} | ${policy.insuredName} | ${policy.plan}`}
                        </MenuItem>
                      )
                    }
                  })}
                </Select>
              </Stack>
            </Paper> */}

            <Paper variant="outlined" sx={{ p: 3, mt: 0 }}>
              {/* <Paper variant="outlined" sx={{ p: 3 }}> */}
              <Typography variant="h6">Policy Information</Typography>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Policy Number
                  </Typography>
                  <Typography variant="h6" color="text.primary" style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                    {policyList[selectedPolicyPlanIndex]?.policyNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Insured Name
                  </Typography>
                  <Typography variant="h6" color="text.primary" style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                    {policyList[selectedPolicyPlanIndex]?.insuredName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Due Date
                  </Typography>
                  <Typography variant="h6" color="text.primary" style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                    {moment(policyList[selectedPolicyPlanIndex]?.dueDate, 'MM/DD/YYYY').isValid() ? moment(policyList[selectedPolicyPlanIndex]?.dueDate, 'MM/DD/YYYY').format('MMM DD, YYYY') : null}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Email Address
                  </Typography>
                  <Typography variant="h6" color="text.primary" style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                    {policyList[selectedPolicyPlanIndex]?.email}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Pay Mode{" "}
                  </Typography>
                  <Typography variant="h6" color="text.primary" style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                    {policyList[selectedPolicyPlanIndex]?.payMode}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Plan{" "}
                  </Typography>
                  <Typography variant="h6" color="text.primary" style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                    {policyList[selectedPolicyPlanIndex]?.plan}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="subtitle2" color="text.subtitle2">
                    Status{" "}
                  </Typography>
                  {/* {isEligible === false ?
                    <Typography variant="h6" color={"text.error"} style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                      {'INVALID'}
                    </Typography>
                    :
                    <Typography variant="h6" color={justPaid ? "text.success" : "text.error"} style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                      {justPaid ? "PAID" : policyList[selectedPolicyPlanIndex]?.status}
                    </Typography>
                  } */}
                  <Typography variant="h6" style={{ whiteSpace: "unset", wordBreak: "break-all" }}>
                    {policyList[selectedPolicyPlanIndex]?.status}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>

            {isEligible === false ?
              <>
                <Paper variant="outlined" sx={{ p: 3, mt: 3 }}>
                  {/* <Typography variant="h6">Policy is not eligible for Online Payment</Typography> */}
                  <Typography variant="h6">{policyList[selectedPolicyPlanIndex]?.reason}</Typography>
                </Paper>
              </>
              :
              <>
                <Paper variant="outlined" sx={{ p: 3, mt: 3 }}>
                  <Stack>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Payment Information
                    </Typography>
                    <Typography variant="overline">Select a Payment Type</Typography>

                    <Select
                      size="small"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      //   defaultValue="Select a Policy
                      value={payment}
                      onChange={handleChangePayment}
                    >
                      <MenuItem value="">
                        <em>Select a Payment Type</em>
                      </MenuItem>
                      {paymentTypes.map((data, index) => {
                        const paymentType = data["PaymentType"];
                        const paymentTypeCode = data["PaymentTypeCode"];
                        return <MenuItem key={index} value={paymentTypeCode}>{paymentType}</MenuItem>
                      })}
                      {/* <MenuItem value="Credit Card">Credit/Debit Card</MenuItem>
                    <MenuItem value="Bank Transfer">Bank Transfer</MenuItem> */}

                    </Select>
                    <TableContainer>
                      <Table sx={{}} aria-label="simple table">
                        <TableHead>
                          <TableRow sx={{ fontWeight: "600" }}>
                            <TableCell align="left" sx={{ fontWeight: "600" }}>
                              Item
                            </TableCell>
                            <TableCell align="right" sx={{ fontWeight: "600" }}>
                              Amount
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow
                            hover
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              <FormControlLabel
                                control={<Checkbox />}
                                checked={true}
                                label="Premium"
                              />
                            </TableCell>
                            <TableCell align="right">
                              ₱ {policyList[selectedPolicyPlanIndex]?.premiumAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </TableCell>
                          </TableRow>

                          <TableRow />
                          <TableRow
                            hover
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              <FormControlLabel
                                control={<Checkbox />}
                                checked={payPDF}
                                label="PDF"
                                onClick={() => {
                                  setPayPDF(!payPDF)
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              ₱ {policyList[selectedPolicyPlanIndex]?.pdf.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </TableCell>
                          </TableRow>

                          <TableRow />
                          <TableRow
                            hover
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              <FormControlLabel
                                control={<Checkbox />}
                                checked={payFBR}
                                label="FBR"
                                onClick={() => {
                                  setPayFBR(!payFBR)
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              ₱ {policyList[selectedPolicyPlanIndex]?.fbr.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </TableCell>
                          </TableRow>
                          <TableRow
                            hover
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              <FormControlLabel
                                control={<Checkbox />}
                                checked={payLoanInterest}
                                label="Policy Loan Interest"
                                onClick={() => {
                                  setPayLoanInterest(!payLoanInterest)
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              ₱ {policyList[selectedPolicyPlanIndex]?.policyLoanInterest.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </TableCell>
                          </TableRow>

                          <TableRow
                            hover
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell align="left" component="th" scope="row">
                              <Typography variant="subtitle2">
                                Total Amount Due
                              </Typography>
                            </TableCell>
                            <TableCell align="right">₱ {totalAmountDue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                          </TableRow>

                          <TableRow />
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Stack>
                </Paper>
              </>}
          </Grid>

          <Grid item xs={12} lg={4}>
            <Paper variant="outlined" sx={{ p: 3 }}>
              <Typography variant="h6">Summary</Typography>
              {isEligible === false ?
                <Typography variant="body2" color={justPaid ? "text.error" : "text.primary"} sx={{ mt: 1 }}>
                  Policy is not eligible for Online Payment
                </Typography>
                :
                <>
                  {justPaid ?
                    <Typography variant="body2" color={justPaid ? "text.success" : "text.primary"} sx={{ mt: 1 }}>
                      Policy is paid
                    </Typography>
                    :
                    <Typography variant="body2" color={justPaid ? "text.success" : "text.primary"} sx={{ mt: 1 }}>
                      Please review the terms and conditions to continue with Payment
                    </Typography>
                  }
                </>
              }

              {!justPaid && isEligible != false ?
                <>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
                    disabled={justPaid}
                  >
                    <Typography variant="body" color={justPaid ? "text.disabled" : "text.gray"}>Policy Number</Typography>
                    <Typography variant="body" color={justPaid ? "text.disabled" : "text.gray"}>
                      <strong>{policyList[selectedPolicyPlanIndex]?.policyNumber}</strong>
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
                    disabled={justPaid}
                  >
                    <Typography variant="body" color={justPaid ? "text.disabled" : "text.gray"}>Total</Typography>
                    <Typography variant="body" color={justPaid ? "text.disabled" : "text.gray"}>
                      <strong>₱ {totalAmountDue?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong>
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={<Stack direction="row"><Typography>I accept the</Typography>&nbsp;<Typography color={justPaid ? "text.disabled" : "text.gray"}>Terms & Conditions</Typography> </Stack>}
                    checked={accepted}
                    sx={{ mt: 4 }}
                    onClick={handleOpen}
                    disabled={justPaid}
                  />
                  {/* <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <Checkbox sx={{pl: 0}} checked={accepted}/>
          <Typography variant="body" color="text.primary">
               I accept the <Typography color="text.linkActive" onClick={handleOpen}>terms & conditions</Typography>
              </Typography>
          </Stack>  */}
                  {/* <Button variant="contained" onClick={handleOpen} sx={{ mt: 4 }}>
              Proceed to Payment
            </Button>*/}

                  <div>
                    {accepted === true ? (
                      <Button variant="contained" sx={{ mt: 4 }} onClick={() => {
                        doPayment()
                      }}>
                        Proceed to Payment
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={handleOpen}
                        sx={{ mt: 4 }}
                        disabled
                      >
                        Proceed to Payment
                      </Button>
                    )}
                  </div>
                </>
                :
                <></>}
            </Paper>
          </Grid>
        </Grid>

      </APILoadingOverlay>
    </div >
  )
}
