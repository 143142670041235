import { createSlice } from "@reduxjs/toolkit";
export const idleControlSlice = createSlice({
  name: "idleState",
  //this is the initial state
  initialState: {
    value: '',
  },
  //reducers: these are the functions for updating the state of loadingState
  reducers: {
    updateIdleState: (state, action) => {
      const { payload } = action
      state.value = payload
    },
  },
});

export const { updateIdleState } = idleControlSlice.actions;
export default idleControlSlice.reducer;
