import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import { getFirestore } from "firebase/firestore";
// import "firebase/storage";
import "firebase/compat/functions";
const firebaseApp = firebase.initializeApp(
    {
        apiKey: process.env.REACT_APP_API_KEY,
        authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        projectId: process.env.REACT_APP_PROJECT_ID,
        storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
        messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
        appId: process.env.REACT_APP_APP_ID,
        measurementId: process.env.REACT_APP_MEASUREMENT_ID
    }
)
export const auth = firebaseApp.auth()
export const firestoreDB = getFirestore();
export const functions = firebaseApp.functions()
export default !firebase.apps.length
    ? firebaseApp
    : firebase.app();